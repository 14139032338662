import React, { useEffect, useState } from 'react';
import { Header2 } from '../../components/Header/HeaderStyle';
import CommonHeader from '../../components/CommonHeader';
import {
	Layout,
	Row,
	Col,
	Button,
	Tag,
	Card,
	Divider,
	Dropdown,
	Modal,
} from 'antd';
import SubmitButton, { Wrapper } from '../../styles/Button';
import {
	ArrowLeftOutlined,
	EnvironmentOutlined,
	UserOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import * as XLSX from 'xlsx/xlsx.mjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';
import { getJobFromInventoryApi } from '../../actions/getJobFromInventoryAction';
import { connect } from 'react-redux';
// import { getJobApplicationsApi } from '../../actions/';
import HeaderFile from '../../components/Header/HeaderFile';
import { getJobApplicationsApi } from '../../actions/getJobApplicationsAction';
import { updateJobStatusApi } from '../../actions/updateJobStatusAction';
import {
	checkModulePermissions,
	getSignedUrlForResumeGetObject,
} from '../../utils';
const { Header } = Layout;

const items = [
	{
		key: '1',
		label: 'Option 1',
	},
	{
		key: '2',
		label: 'Option 2',
	},
	{
		key: '3',
		label: 'Option 3',
	},
];
const InventoryView = (props) => {
	let modulePermissions = checkModulePermissions('jobs-inventory');

	const {
		getJobFromInventory,
		getJobFromInventoryState,
		getJobApplications,
		getJobApplicationsState,
		// updateJobStatusState,
		updateJobStatus,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;

	const [publishModal, setPublishModal] = useState(false);
	const [activeModal, setActiveModal] = useState(false);
	const [removeModal, setRemoveModal] = useState(false);
	const [draftModal, setDraftModal] = useState(false);
	const [inactiveModal, setInactiveModal] = useState(false);
	const [makeactive, setMakeactive] = useState(false);
	const [makeInactive, setMakeInactive] = useState(false);
	const [inventoryJobData, setInventoryJobData] = useState();
	const [displaySourcingFeesValue, setDisplaySourcingFeesValue] =
		useState('');
	const [displaySourcingFeesPercentage, setDisplaySourcingFeesPercentage] =
		useState('');
	const [
		displaySourcingAnnualPercentage,
		setDisplaySourcingAnnualPercentage,
	] = useState('');
	const [exportLoading, setExportLoading] = useState(false);

	const gotToPublish = () => {
		navigate('/inventory');
	};
	/*
  const publishJob = () => {
    setPublish(false);
    setMakeactive(true);
  };
*/
	/*
  const publishJob = () => {
    publish.confirm({
      title: `Are you sure you want to publish the job?`,
      icon: (
        <img
          width="40"
          className="mr-2"
          style={{ float: "left" }}
          src="/images/confirm-icon.svg"
          alt=""
        />
      ),
      content: (
        <p className="confirmPara">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      ),
      okText: "Confirm",
      cancelText: "Cancel",
      className: "confirmbox",
    });
  };
*/

	useEffect(() => {
		getJobFromInventory({ id });
	}, []);

	useEffect(() => {
		setInventoryJobData(getJobFromInventoryState.data);
	}, [getJobFromInventoryState, inventoryJobData]);

	useEffect(() => {
		if (getJobFromInventoryState.apiState === 'success') {
			getJobApplications(getJobFromInventoryState.data._id);
		}
	}, [getJobFromInventoryState.apiState]);

	const makeActive = () => {
		setMakeactive(false);
		setMakeInactive(true);
	};
	const makeReInactive = () => {
		//setPublish(true);
		setMakeactive(false);
		setMakeInactive(false);
	};

	//Get the number of days, weeks
	const getDays = (date) => {
		const today = new Date();
		const selectedDate = new Date(date);
		const timeDiff = Math.abs(selectedDate.getTime() - today.getTime());
		const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		if (diffDays === 1) {
			return 'Today';
		} else if (diffDays === 2) {
			return 'Yesterday';
		} else if (diffDays > 2 && diffDays < 7) {
			return `${diffDays} days ago`;
		} else {
			return Math.floor(diffDays / 7) === 1
				? `${Math.floor(diffDays / 7)} week ago`
				: `${Math.floor(diffDays / 7)} weeks ago`;
		}
	};

	const exportCandidates = async (applications) => {
		try {
			// Extract candidate data
			const candidateData = await Promise.all(
				applications.map(async (app) => {
					const candidate = app.candidate_id;
					let resumeUrl = await getSignedUrlForResumeGetObject(
						candidate.resume,
					);

					return [
						candidate._id,
						candidate.first_name,
						candidate.last_name,
						candidate.mobile_number,
						candidate.email_address,
						candidate.reseller_id,
						candidate.dateOfBirth,
						candidate.age,
						candidate.skills.join(', '),
						candidate.jobs.join(', '),
						candidate.education.join(', '),
						candidate.bike,
						candidate.byod,
						candidate.divyang,
						candidate.driving_license,
						candidate.laptop,
						candidate.smart_phone,
						candidate.wifi,
						candidate.wifi_connection,
						candidate.have_aadhar_card,
						candidate.have_pan_card,
						candidate.passport_size_photo,
						resumeUrl,
						candidate.preferred_job_location.join(', '),
						candidate.preferred_job_type.join(', '),
						candidate.preferred_industry.join(', '),
						candidate.preferred_job_roles.join(', '),
						candidate.willing_to_relocate,
						candidate.current_ctc_monthly,
						candidate.current_ctc_yearly,
						candidate.expected_ctc_monthly,
						candidate.expected_ctc_yearly,
						candidate.have_additional_certification,
						candidate.hired,
						candidate.joined,
						candidate.eligible_to_bill,
						candidate.shortlisted,
						candidate.is_personal_information_completed,
						candidate.is_address_details_completed,
						candidate.is_ctc_information_completed,
						candidate.is_id_proofs_completed,
						candidate.is_education_information_completed,
						candidate.is_work_experience_completed,
						candidate.is_training_information_completed,
						candidate.is_professional_summary_completed,
						candidate.is_addon_information_completed,
						candidate.is_resume_completed,
						candidate.profile_complete_percentage,
						candidate.joined_on,
						candidate.is_active,
						candidate.deleted_at,
						candidate.work_experience
							.map(
								(exp) =>
									`${exp.company_name} (${exp.from} - ${exp.to}) | ${exp.designation} | ${exp.roles_and_responsibilities}`,
							)
							.join(' || '),
						candidate.certifications
							.map(
								(cert) =>
									`${cert.certification_name} (${cert.issuing_organization}) | ${cert.issuing_date} | ${cert.expiry_date} | ${cert.training_description}`,
							)
							.join(' || '),
						candidate.createdAt,
						candidate.updatedAt,
					];
				}),
			);

			// Extract job inventory data
			const jobInventoryData = applications.map((app) => {
				const job = app.job_inventory_id;
				return [
					app._id,
					job.job_title,
					app.status,
					app.commission,
					app.ctc,
					app.payment_status,
					app.purchase_order_id,
					app.createdAt,
					app.updatedAt,
				];
			});

			// Extract reseller data
			const resellerData = applications.map((app) => {
				const reseller = app.reseller_id;
				return [app._id, reseller.first_name];
			});

			// Create headers for the sheets
			const candidateHeaders = [
				'Candidate ID',
				'First Name',
				'Last Name',
				'Mobile Number',
				'Email Address',
				'Reseller ID',
				'Date of Birth',
				'Age',
				'Skills',
				'Jobs',
				'Education',
				'Bike',
				'BYOD',
				'Divyang',
				'Driving License',
				'Laptop',
				'Smart Phone',
				'WiFi',
				'WiFi Connection',
				'Aadhar Card',
				'Pan Card',
				'Passport Size Photo',
				'Resume',
				'Preferred Job Location',
				'Preferred Job Type',
				'Preferred Industry',
				'Preferred Job Roles',
				'Willing to Relocate',
				'Current CTC Monthly',
				'Current CTC Yearly',
				'Expected CTC Monthly',
				'Expected CTC Yearly',
				'Additional Certification',
				'Hired',
				'Joined',
				'Eligible to Bill',
				'Shortlisted',
				'Personal Info Completed',
				'Address Details Completed',
				'CTC Info Completed',
				'ID Proofs Completed',
				'Education Info Completed',
				'Work Experience Completed',
				'Training Info Completed',
				'Professional Summary Completed',
				'Addon Info Completed',
				'Resume Completed',
				'Profile Complete Percentage',
				'Joined On',
				'Active',
				'Deleted At',
				'Work Experience',
				'Certifications',
				'Created At',
				'Updated At',
			];
			const jobInventoryHeaders = [
				'Job ID',
				'Job Title',
				'Status',
				'Commission',
				'CTC',
				'Payment Status',
				'Purchase Order ID',
				'Created At',
				'Updated At',
			];
			const resellerHeaders = ['Job ID', 'First Name'];

			// Create a new workbook and worksheets
			const workbook = XLSX.utils.book_new();

			const candidateSheet = XLSX.utils.aoa_to_sheet([
				candidateHeaders,
				...candidateData,
			]);
			const jobInventorySheet = XLSX.utils.aoa_to_sheet([
				jobInventoryHeaders,
				...jobInventoryData,
			]);
			const resellerSheet = XLSX.utils.aoa_to_sheet([
				resellerHeaders,
				...resellerData,
			]);

			// Append the worksheets to the workbook
			XLSX.utils.book_append_sheet(
				workbook,
				candidateSheet,
				'Candidates',
			);
			XLSX.utils.book_append_sheet(
				workbook,
				jobInventorySheet,
				'Job Inventory',
			);
			XLSX.utils.book_append_sheet(workbook, resellerSheet, 'Resellers');

			// Convert the workbook to a binary "Blob"
			const workbookBlob = XLSX.write(workbook, {
				type: 'buffer',
				bookType: 'xlsx',
			});
			const file = new Blob([workbookBlob], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			// Create a download link and trigger the download
			const downloadLink = document.createElement('a');
			downloadLink.href = URL.createObjectURL(file);
			downloadLink.download = 'Candidates.xlsx';
			downloadLink.click();
		} catch (error) {
			console.error('Error exporting candidates:', error);
		} finally {
			setExportLoading(false); // Reset loading state here
		}
	};

	//Render perks and prerequisites
	const renderTag = (value) => {
		return (
			<Tag className="customTags" title="Smart Phone">
				{value}
			</Tag>
		);
	};

	//Render the job description and job specifications
	const renderList = (list) => {
		const sentenceArray = list?.split('.');
		if (sentenceArray.length > 1)
			sentenceArray?.splice(sentenceArray.length - 1, 1);

		return (
			<ul className="themeDotList">
				{sentenceArray?.map((sentence) => (
					<li>{sentence}</li>
				))}
			</ul>
		);
	};

	const handleJobStatus = (id, status) => {
		updateJobStatus({ id, status });
		setPublishModal(false);
		setActiveModal(false);
		setRemoveModal(false);
		setDraftModal(false);
		setInactiveModal(false);
		navigate('/inventory');
	};

	useEffect(() => {
		if (getJobFromInventoryState.apiState === 'success')
			if (
				getJobFromInventoryState.data.sourcing_fees_criteria === 'fixed'
			) {
				setDisplaySourcingFeesValue(
					`₹ ${getJobFromInventoryState.data.sourcing_fixed_value} of Annual CTC`,
				);
			} else if (
				getJobFromInventoryState.data.sourcing_fees_criteria ===
				'percentage'
			) {
				if (getJobFromInventoryState.data.salary > 0) {
					setDisplaySourcingFeesValue(
						`₹ ${
							(getJobFromInventoryState.data
								.sourcing_percentage_value /
								100) *
							getJobFromInventoryState.data.salary
						}`,
					);
				} else {
					setDisplaySourcingFeesValue(
						`₹ ${
							(getJobFromInventoryState.data
								.sourcing_percentage_value /
								100) *
							getJobFromInventoryState.data.salary_start
						} - ₹ ${
							(getJobFromInventoryState.data
								.sourcing_percentage_value /
								100) *
							getJobFromInventoryState.data.salary_end
						}`,
					);
				}
				setDisplaySourcingAnnualPercentage(
					getJobFromInventoryState.data.sourcing_percentage_value,
				);
			}

		// Do something with the displaySourcingFeesValue, such as updating state or rendering it in the component
	}, [getJobFromInventoryState.apiState]);

	if (inventoryJobData)
		return (
			<>
				{modulePermissions.authorized ? (
					<>
						<div className="mainContent">
							<div className="pageWrapper">
								<Wrapper>
									<Row gutter={[30, 30]} align="top">
										<Col xs={24} md={16}>
											<Card className="card">
												<Row gutter={[30, 0]}>
													<Col span={24}>
														<h1 className="headerheading">
															{
																inventoryJobData.job_title
															}
															{/* <div
																style={{
																	float: 'right',
																}}
															>
																<Dropdown
																	menu={{
																		items,
																	}}
																	placement="bottomRight"
																	arrow
																>
																	<Button
																		type="Default"
																		shape="circle"
																	>
																		<MoreOutlined
																			style={{
																				fontSize:
																					'22px',
																			}}
																		/>
																	</Button>
																</Dropdown>
															</div> */}
														</h1>
														<p className="para2">
															{
																inventoryJobData.employer
															}
														</p>
														<Row gutter={[15, 30]}>
															<Col xs={24}>
																<p className="para3 mb-2">
																	<span>
																		Pre-requisites
																	</span>
																</p>

																{inventoryJobData.byod ===
																true
																	? renderTag(
																			'BYOD',
																	  )
																	: null}
																{inventoryJobData.divyang ===
																true
																	? renderTag(
																			'Divyang',
																	  )
																	: null}
																{inventoryJobData.driving_licence ===
																true
																	? renderTag(
																			'Driving Licence',
																	  )
																	: null}
																{inventoryJobData.bike ===
																true
																	? renderTag(
																			'Bike',
																	  )
																	: null}
																{inventoryJobData.laptop ===
																true
																	? renderTag(
																			'Laptop',
																	  )
																	: null}
																{inventoryJobData.wifi_connection ===
																true
																	? renderTag(
																			'Wifi',
																	  )
																	: null}
															</Col>
															<Col xs={24}>
																<p className="para3 mb-2">
																	<span>
																		Perks
																	</span>
																</p>
																{inventoryJobData.lunch ===
																true
																	? renderTag(
																			'Lunch',
																	  )
																	: null}
																{inventoryJobData.snacks ===
																true
																	? renderTag(
																			'Snacks',
																	  )
																	: null}
																{inventoryJobData.accomodation ===
																true
																	? renderTag(
																			'Accomodation',
																	  )
																	: null}
																{inventoryJobData.overtime ===
																true
																	? renderTag(
																			'Overtime',
																	  )
																	: null}
																{inventoryJobData.pick_and_drop ===
																true
																	? renderTag(
																			'Pick and Drop',
																	  )
																	: null}
															</Col>
														</Row>
														<Row
															gutter={[30, 30]}
															className="mt-10"
														>
															<Col lg={6}>
																<p className="para3 mb-2">
																	<span>
																		Job Type
																	</span>
																</p>
																<Tag
																	className="customTags"
																	title="Smart Phone"
																>
																	{inventoryJobData.job_type.includes(
																		'_',
																	)
																		? inventoryJobData.job_type
																				.charAt(
																					0,
																				)
																				.toUpperCase() +
																		  inventoryJobData.job_type
																				.slice(
																					1,
																				)
																				.split(
																					'_',
																				)
																				.join(
																					'',
																				)
																		: inventoryJobData.job_type
																				.charAt(
																					0,
																				)
																				.toUpperCase() +
																		  inventoryJobData.job_type.slice(
																				1,
																		  )}
																</Tag>
															</Col>
															<Col lg={18}>
																<p className="para3 mb-2">
																	<span>
																		Payroll
																		Organization
																	</span>
																</p>
																<Tag
																	className="customTags"
																	title="Smart Phone"
																>
																	{
																		inventoryJobData.payroll_organization
																	}
																</Tag>
															</Col>
															<Col lg={6}>
																<p className="para3 mb-2">
																	<span>
																		Industry
																	</span>
																</p>
																<Tag
																	className="customTags"
																	title="Smart Phone"
																>
																	{
																		inventoryJobData.industry
																	}
																</Tag>
															</Col>
															<Col lg={18}>
																<p className="para3 mb-2">
																	<span>
																		Function
																	</span>
																</p>
																<Tag
																	className="customTags"
																	title="Smart Phone"
																>
																	{
																		inventoryJobData.category
																	}
																</Tag>
															</Col>
															<Col lg={24}>
																<p className="para3 mb-2">
																	<span>
																		Job
																		Collar
																	</span>
																</p>
																<Tag
																	className="customTags"
																	title="Smart Phone"
																>
																	{inventoryJobData.job_collar
																		.charAt(
																			0,
																		)
																		.toUpperCase() +
																		inventoryJobData.job_collar.slice(
																			1,
																		)}
																</Tag>
															</Col>
														</Row>

														<p className="para3 my-5">
															<span>
																Job Description
															</span>
														</p>

														{renderList(
															inventoryJobData.job_description,
														)}
														<p className="para3 my-5">
															<span>
																Special
																Requirements
															</span>
														</p>
														{renderList(
															inventoryJobData.special_requirements,
														)}
													</Col>
												</Row>
											</Card>
										</Col>
										<Col xs={24} md={8}>
											<Row>
												<Col span={24}>
													{inventoryJobData.status ===
														'draft' && (
														<>
															{modulePermissions.sub_modules.includes(
																'publish-jobs',
															) && (
																<SubmitBtn
																	onClick={() => {
																		setPublishModal(
																			true,
																		);
																	}}
																	style={{
																		width: '100%',
																	}}
																	type="primary"
																	htmlType="submit"
																>
																	Publish Job
																</SubmitBtn>
															)}
															{modulePermissions.sub_modules.includes(
																'remove-jobs',
															) && (
																<SubmitNoBgBtn
																	style={{
																		width: '100%',
																		color: 'rgba(230, 104, 37, 1)',
																	}}
																	type="primary"
																	htmlType="submit"
																	onClick={() => {
																		setRemoveModal(
																			true,
																		);
																	}}
																>
																	Remove Job
																</SubmitNoBgBtn>
															)}
														</>
													)}

													{inventoryJobData.status ===
														'published' &&
														!inventoryJobData.is_active && (
															<>
																<SubmitBtn
																	onClick={() => {
																		setActiveModal(
																			true,
																		);
																	}}
																	style={{
																		width: '100%',
																		backgroundColor:
																			'green',
																	}}
																	type="primary"
																	htmlType="submit"
																>
																	Make Active
																</SubmitBtn>
																<SubmitNoBgBtn
																	style={{
																		width: '100%',
																		color: 'rgba(230, 104, 37, 1)',
																	}}
																	type="primary"
																	htmlType="submit"
																	onClick={() => {
																		setDraftModal(
																			true,
																		);
																	}}
																>
																	Move to
																	Drafts
																</SubmitNoBgBtn>
															</>
														)}

													{inventoryJobData.status ===
														'published' &&
														inventoryJobData.is_active && (
															<>
																<SubmitNoBgBtn
																	style={{
																		width: '100%',
																		backgroundColor:
																			'#EAA521																',
																		color: '#fff',
																	}}
																	type="primary"
																	htmlType="submit"
																	onClick={() => {
																		setInactiveModal(
																			true,
																		);
																	}}
																>
																	Make
																	Inactive
																</SubmitNoBgBtn>
															</>
														)}

													{/* <SubmitNoBgBtn
														loading={exportLoading}
														style={{
															width: '100%',
															color: 'rgba(230, 104, 37, 1)',
														}}
														type="primary"
														htmlType="submit"
														onClick={() => {
															setExportLoading(
																true,
															);
															exportCandidates(
																getJobApplicationsState.data,
															);
														}}
													>
														Export Candidates
													</SubmitNoBgBtn> */}
													<SubmitButton
														loading={exportLoading}
														disabled={exportLoading}
														style={{
															width: '100%',
															color: 'rgba(230, 104, 37, 1)',
														}}
														type="primary"
														htmlType="submit"
														onClick={() => {
															setExportLoading(
																true,
															);
															exportCandidates(
																getJobApplicationsState.data,
															);
														}}
													>
														Export Candidates
													</SubmitButton>
												</Col>
												<Col span={24}>
													<Card
														style={{
															marginTop: 16,
														}}
														type="inner"
														title={
															<div className="cardheaderIcon">
																<img
																	width={15}
																	className="mr-3"
																	src="../../images/icon-12.svg"
																	alt=""
																/>
																{inventoryJobData.salary_defined_type ===
																'fixed'
																	? inventoryJobData.salary_type ===
																	  'monthly'
																		? `₹ ${inventoryJobData.salary} PM`
																		: `₹ ${inventoryJobData.salary} Annually`
																	: `₹ ${inventoryJobData.salary_start} - ₹ ${inventoryJobData.salary_end} Annually`}{' '}
															</div>
														}
														className="customCard"
													>
														<p className="para2">
															<EnvironmentOutlined className="mr-2" />
															{
																inventoryJobData.city
															}
															, &nbsp;
															{
																inventoryJobData.state
															}
														</p>
														<p className="para2 mt-4 mb-5">
															<UserOutlined className="mr-2" />
															{
																inventoryJobData.number_of_openings
															}
														</p>
														<p className="para3 mt-5">
															Minimum Retention
															Period :&nbsp;
															<span>
																{
																	inventoryJobData.min_retention_period
																}
																&nbsp;days
															</span>
														</p>
														<p className="para3 mt-1">
															Refresh ‘Job posted
															date’ :&nbsp;
															<span>
																{
																	inventoryJobData.refresh_job_posted_date_in
																}
																&nbsp;Days
															</span>
														</p>
														<p className="para3 mt-5">
															Posted :&nbsp;
															<span>
																{inventoryJobData.job_posted_date
																	? getDays(
																			inventoryJobData.job_posted_date,
																	  )
																	: ''}
															</span>
														</p>
														<Divider />
														<p className="para2">
															SK Sourcing Fees
														</p>
														<div
															className="cardheaderIcon my-2"
															style={{
																background:
																	'none',
															}}
														>
															{
																displaySourcingFeesValue
															}
															{
																displaySourcingFeesPercentage
															}
														</div>
														{inventoryJobData.status ===
															'draft' &&
															inventoryJobData.sourcing_fees_criteria ===
																'percentage' && (
																<div
																	className="cardheaderIcon"
																	style={{
																		background:
																			'none',
																	}}
																>
																	{`${displaySourcingAnnualPercentage} of Annual
																CTC`}
																</div>
															)}
													</Card>
												</Col>
											</Row>
										</Col>
									</Row>
								</Wrapper>
							</div>
						</div>
						{/***** publish modal */}
						<Modal
							title={
								<>
									<img
										width="40"
										className="mr-2"
										style={{ float: 'left' }}
										src="/images/confirm-icon.svg"
										alt=""
									/>
									Are you sure you want to publish the job?
								</>
							}
							centered
							open={publishModal}
							onOk={() => {
								handleJobStatus(
									inventoryJobData._id,
									'published',
								);
							}}
							onCancel={() => setPublishModal(false)}
							className="customModal"
							okText="Confirm"
							cancelText="Cancel"
						>
							<Row>
								<Col>
									<p className="confirmPara">
										Publishing this job will make it visible
										to potential candidates. Ensure that all
										the job details are accurate and
										complete before proceeding. Do you wish
										to continue?
									</p>
								</Col>
							</Row>
						</Modal>

						{/***** Active modal */}
						<Modal
							title={
								<>
									<img
										width="40"
										className="mr-2"
										style={{ float: 'left' }}
										src="/images/confirm-icon.svg"
										alt=""
									/>
									Are you sure you want to make the job
									active?
								</>
							}
							centered
							open={activeModal}
							onOk={() => {
								handleJobStatus(inventoryJobData._id, 'active');
							}}
							onCancel={() => setActiveModal(false)}
							className="customModal"
							okText="Confirm"
							cancelText="Cancel"
						>
							<Row>
								<Col>
									<p className="confirmPara">
										Activating this job will make it visible
										to potential candidates. Ensure that all
										the job details are correct before
										proceeding. Do you wish to continue?
									</p>
								</Col>
							</Row>
						</Modal>

						{/***** Remove modal */}
						<Modal
							title={
								<>
									<img
										width="40"
										className="mr-2"
										style={{ float: 'left' }}
										src="/images/confirm-icon.svg"
										alt=""
									/>
									Are you sure you want to remove the job?
								</>
							}
							centered
							open={removeModal}
							onOk={() => {
								handleJobStatus(inventoryJobData._id, 'remove');
							}}
							onCancel={() => setRemoveModal(false)}
							className="customModal"
							okText="Confirm"
							cancelText="Cancel"
						>
							<Row>
								<Col>
									<p className="confirmPara">
										Removing this job will make it
										unavailable to potential candidates.
										Ensure that you want to proceed with
										this action.
									</p>
								</Col>
							</Row>
						</Modal>

						{/***** Move to drafts modal */}
						<Modal
							title={
								<>
									<img
										width="40"
										className="mr-2"
										style={{ float: 'left' }}
										src="/images/confirm-icon.svg"
										alt=""
									/>
									Are you sure you want to move the job to
									drafts?
								</>
							}
							centered
							open={draftModal}
							onOk={() => {
								handleJobStatus(inventoryJobData._id, 'draft');
							}}
							onCancel={() => setDraftModal(false)}
							className="customModal"
							okText="Confirm"
							cancelText="Cancel"
						>
							<Row>
								<Col>
									<p className="confirmPara">
										Moving this job to drafts will make it
										unavailable to potential candidates. You
										can edit and republish it later. Do you
										wish to continue?
									</p>
								</Col>
							</Row>
						</Modal>

						{/***** Move to drafts modal */}
						<Modal
							title={
								<>
									<img
										width="40"
										className="mr-2"
										style={{ float: 'left' }}
										src="/images/confirm-icon.svg"
										alt=""
									/>
									Are you sure you want to make the job
									Inactive?
								</>
							}
							centered
							open={inactiveModal}
							onOk={() => {
								handleJobStatus(
									inventoryJobData._id,
									'deactive',
								);
							}}
							onCancel={() => setInactiveModal(false)}
							className="customModal"
							okText="Confirm"
							cancelText="Cancel"
						>
							<Row>
								<Col>
									<p className="confirmPara">
										Making this job inactive will hide it
										from potential candidates. You can
										reactivate it later if needed. Do you
										wish to continue?
									</p>
								</Col>
							</Row>
						</Modal>
					</>
				) : (
					<>
						<div className="mainContent">
							<div className="pageWrapper">
								<Wrapper>
									<Row>
										<Col
											xs={24}
											className="mt-5"
											align="center"
										>
											<img
												style={{ maxWidth: '100%' }}
												src="./images/empty-role.svg"
												alt=""
											/>
										</Col>
										<Col
											xs={24}
											className="my-5"
											align="center"
										>
											<h1>Unauthorized to view jobs</h1>
											<br />
											<br />
											<p>
												Please Contact System
												Administrator for further
												assistance
											</p>
										</Col>
									</Row>
								</Wrapper>
							</div>
						</div>
					</>
				)}
			</>
		);
};

const mapStateToProps = (state) => ({
	getJobFromInventoryState: state.getJobFromInventory,
	getJobApplicationsState: state.getJobApplications,
	// updateJobStatusState: state.updateJobStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getJobFromInventory: (params) => dispatch(getJobFromInventoryApi(params)),
	getJobApplications: (params) => dispatch(getJobApplicationsApi(params)),
	updateJobStatus: (params) => dispatch(updateJobStatusApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryView);
