import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Table,
	Pagination,
	Row,
	Col,
	Card,
	Typography,
	Button,
	Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { TabBtnBg } from '../../styles/Button';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
	capitalizeAndSplitByDash,
	getSignedUrlForGetObject,
} from '../../utils';

// API Action

const EmployersTable = (props) => {
	const { filters, setFilters, pagination, setPagination, tableData } = props;

	const handleOnClick = async (agreement_url) => {
		const url = await getSignedUrlForGetObject(
			'employers',
			agreement_url[0],
			agreement_url[1],
		);
		window.open(url, '_blank');
	};

	const columns = [
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					EMPLOYER
				</Typography.Text>
			),
			dataIndex: 'employer',
			render: (employers) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{/* {capitalizeAndSplitByDash(employers)} */}
						{employers}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					INDUSTRY
				</Typography.Text>
			),
			dataIndex: 'industry',
			render: (industry) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{capitalizeAndSplitByDash(industry)}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					STATE
				</Typography.Text>
			),
			dataIndex: 'state',
			render: (state) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{capitalizeAndSplitByDash(state)}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					CITY
				</Typography.Text>
			),
			dataIndex: 'city',
			render: (city) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{capitalizeAndSplitByDash(city)}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					ONBOARDED ON
				</Typography.Text>
			),
			dataIndex: 'joinedOn',
			render: (joinedOn) => (
				<div style={{ width: '120px' }}>
					<div style={{ fontWeight: '600' }}>
						{moment(joinedOn).format('DD-MM-YYYY')}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					AGREEMENT
				</Typography.Text>
			),
			dataIndex: 'signed_by_both',
			render: (signed_by_both) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{signed_by_both ? 'Yes' : 'No'}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					AGREEMENT DATE
				</Typography.Text>
			),
			dataIndex: 'date_of_agreement',
			render: (date_of_agreement) => (
				<div style={{ width: '130px' }}>
					<div style={{ fontWeight: '600' }}>
						{date_of_agreement
							? moment(date_of_agreement).format('DD-MM-YYYY')
							: '-'}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					TENURE (IN MONTHS)
				</Typography.Text>
			),
			dataIndex: 'tenure_of_agreement',
			render: (tenure_of_agreement) => (
				<div style={{ width: '150px' }}>
					<div style={{ fontWeight: '600' }}>
						{tenure_of_agreement}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					EXPIRY DATE
				</Typography.Text>
			),
			dataIndex: 'agreement_expiry_date',
			render: (agreement_expiry_date) => (
				<div style={{ width: '120px' }}>
					<div style={{ fontWeight: '600' }}>
						{agreement_expiry_date
							? moment(agreement_expiry_date).format('DD-MM-YYYY')
							: '-'}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					ACTIVE JOBS
				</Typography.Text>
			),
			dataIndex: 'active_jobs',
			render: (active_jobs) => (
				<div style={{ width: '100px' }}>
					<div style={{ fontWeight: '600' }}>{active_jobs}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					ACTIVE OPENINGS
				</Typography.Text>
			),
			dataIndex: 'active_openings',
			render: (active_openings) => (
				<div style={{ width: '120px' }}>
					<div style={{ fontWeight: '600' }}>{active_openings}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					INACTIVE JOBS
				</Typography.Text>
			),
			dataIndex: 'inactive_jobs',
			render: (inactive_jobs) => (
				<div style={{ width: '120px' }}>
					<div style={{ fontWeight: '600' }}>{inactive_jobs}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					INACTIVE OPENINGS
				</Typography.Text>
			),
			dataIndex: 'inactive_openings',
			render: (inactive_openings) => (
				<div style={{ width: '140px' }}>
					<div style={{ fontWeight: '600' }}>{inactive_openings}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					CANDIDATES SHARED
				</Typography.Text>
			),
			dataIndex: 'candidates_shared',
			render: (candidates_shared) => (
				<div style={{ width: '150px' }}>
					<div style={{ fontWeight: '600' }}>{candidates_shared}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					REJECTED
				</Typography.Text>
			),
			dataIndex: 'candidates_rejected',
			render: (candidates_rejected) => (
				<div>
					<div>{candidates_rejected}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					HIRED
				</Typography.Text>
			),
			dataIndex: 'candidates_hired',
			render: (hired) => (
				<div>
					<div>{hired}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					EARNING POTENTIAL
				</Typography.Text>
			),
			dataIndex: 'earning_potential',
			render: (earning_potential) => (
				<div style={{ width: '140px' }}>
					<div>{earning_potential}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					PAYOUT
				</Typography.Text>
			),
			dataIndex: 'commission',
			render: (commission) => (
				<div>
					<div>
						{commission[1] == 'percentage'
							? `${commission[0]} % `
							: `₹ ${commission[0]}`}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					AGREEMENT
				</Typography.Text>
			),
			dataIndex: 'agreement_url',
			render: (agreement_url) => {
				return (
					<div onClick={() => handleOnClick(agreement_url)}>
						<Tooltip title="Download Agreement">
							<Button
								type="link"
								onClick={(e) => e.preventDefault()}
							>
								<img src="images/agreement-icon.svg" alt="" />
							</Button>
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					ACTION
				</Typography.Text>
			),
			dataIndex: 'buttons',
			render: (buttons) => (
				<div>
					<div>{buttons.button1}</div>
					<div>{buttons.button2}</div>
				</div>
			),
		},
	];

	return (
		<>
			<Table
				loading={!tableData.length > 0}
				scroll={{ x: 991 }}
				columns={columns}
				dataSource={tableData}
				pagination={false}
				className="invtTable"
			/>
			<Pagination
				className="pt-5"
				onChange={(page, pageSize) => {
					let filtr = {
						...filters,
						page: page,
						limit: pageSize,
					};
					setFilters(filtr);
					setPagination({
						...pagination,
						page: page,
						limit: pageSize,
					});
				}}
				current={pagination.page}
				defaultPageSize={pagination.limit}
				defaultCurrent={1}
				total={pagination.total_count}
			></Pagination>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmployersTable);
