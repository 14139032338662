import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Form,
	Select,
	Button,
	Dropdown,
	Upload,
	Card,
	Radio,
	DatePicker,
	InputNumber,
	notification,
	Space,
} from 'antd';
import {
	UploadOutlined,
	PlusOutlined,
	DeleteOutlined,
	ArrowLeftOutlined,
} from '@ant-design/icons';
import {
	SubmitBtn,
	SubmitNoBgBtn,
	ButtonNoBg,
	ButtonBg,
	TabBtnNoBg,
} from '../../styles/Button';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import dayjs from 'dayjs';

// components
import HeaderFile from '../../components/Header/HeaderFile';

// API Action
import {
	addEmployerApi,
	addEmployerReset,
} from '../../actions/addEmployerAction';
import {
	getEmployerByIdApi,
	getEmployerByIdReset,
} from '../../actions/getEmployerByIdAction';
import {
	updateEmployerApi,
	updateEmployerReset,
} from '../../actions/updateEmployerAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';
import {
	getAreaByPincodeApi,
	getAreaByPincodeReset,
} from '../../actions/getAreaByPincodeAction';
import { v4 as uuidv4 } from 'uuid';

//import { Theme } from '@ant-design/cssinjs';
import { Theme } from '../../GolbalStyle';
import { getSignedURLApi } from '../../actions/getSignedURLAction';
import { legalEntityList } from '../../constants';
import {
	validateMobileNumber,
	getSignedUrlForGetObject,
	getFileNameFromDocPath,
	checkModulePermissions,
	getUserToken,
	filterByLabel,
	handleKeyDown,
} from '../../utils';
import moment from 'moment';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

const AddEditEmployers = (props) => {
	let modulePermissions = checkModulePermissions('employers');

	const {
		addEmployer,
		addEmployerState,
		addEmployerReset,
		addEmployerResetState,
		getEmployerById,
		getEmployerByIdReset,
		getEmployerByIdResetState,
		getEmployerByIdState,
		updateEmployer,
		updateEmployerState,
		updateEmployerReset,
		updateEmployerResetState,
		getSignedURLState,
		getSignedURL,
		getIndustriesState,
		getIndustries,
		getAreaByPincode,
		getAreaByPincodeState,
		getAreaByPincodeReset,
	} = props;

	const id = useParams().id || '';
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const formInitial = {
		id: '',
		organization_name: '',
		organization_address: '',
		pincode: '',
		area: '',
		city: '',
		state: '',
		minimum_retention_period: null,
		legal_entity: '',
		industry: '',
		spocs: [
			{
				spoc_name: '',
				spoc_designation: '',
				spoc_email_address: '',
				spoc_mobile_number: '',
				spoc_level: 1,
			},
		],
		sourcing_fees_criteria: 'percentage',
		fixed_value: null,
		percentage_value: null,
		percentage_duration: null,
		cin_number: null,
		cin_document: '',
		cin_document_file: '',
		gstin_number: '',
		gstin_document: '',
		gstin_document_file: '',
		pan_number: '',
		pan_document: '',
		pan_document_file: '',
		msme_registration_number: null,
		msme_registration_document: null,
		msme_registration_document_file: '',
		date_of_agreement: '',
		agreement_document: '',
		tenure_of_agreement: 1,
		signed_by_both: true,
		agreement_expiry_date: '',
		is_active: true,
		total_jobs: 0,
		total_hired: 0,
		total_earnings: 0,
		pending_invoice: 0,
		prefix: 'Months',
	};
	const [formData, setFormData] = useState(formInitial);
	const [industryOptions, setIndustryOptions] = useState([]);
	const [title, setTitle] = useState('Add Employer');
	const [documentsToUpload, setDocumentsToUpload] = useState([]);
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const [uploadedDocuments, setUploadedDocuments] = useState(null);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [industry, setIndustry] = useState([]);
	const [selectedIndustry, setSelectedIndustry] = useState([]);
	const [loading, setLoading] = useState(false);
	const [uploadedDocumentsToS3, setUploadedDocumentsToS3] = useState({});

	useEffect(() => {
		getIndustries({});
		if (id) {
			setUploadedDocuments([
				{ key: 'msme_registration_document' },
				{ key: 'pan_document' },
				{ key: 'cin_document' },
				{ key: 'gstin_document' },
				{ key: 'agreement_document' },
			]);
			getEmployerById({ id: id });
		} else {
			setDataLoaded(true);
			form.setFieldsValue(formData);
		}
	}, [id]);

	useEffect(() => {
		getAreaByPincodeReset();
	}, []);

	useEffect(() => {
		if (getEmployerByIdState.apiState === 'success' && id) {
			setFormData({
				...getEmployerByIdState.data,
				pincode: getEmployerByIdState.data?.pincode.toString(),
				_id: getEmployerByIdState.data._id,
				prefix: 'Months',
			});
			form.setFieldsValue({
				...getEmployerByIdState.data,
				date_of_agreement: getEmployerByIdState?.data?.date_of_agreement
					? dayjs(getEmployerByIdState.data.date_of_agreement)
					: '',
				agreement_expiry_date: getEmployerByIdState?.data
					?.agreement_expiry_date
					? dayjs(getEmployerByIdState.data.agreement_expiry_date)
					: '',
			});
			setTitle(`${getEmployerByIdState.data.organization_name}`);
			setAllUploadedDocuments(getEmployerByIdState.data);
		} else if (getEmployerByIdState.apiState === 'error') {
			console.log('API Error=>', getEmployerByIdState.message);
			notification.error({
				message: 'Unable to get Employer',
			});
		}
		getEmployerByIdReset();
	}, [getEmployerByIdState.apiState]);

	useEffect(() => {
		if (getAreaByPincodeState.apiState === 'success') {
			form.setFieldsValue({
				...form.getFieldsValue(),
				area: getAreaByPincodeState.data.area,
				city: getAreaByPincodeState.data.city,
				state: getAreaByPincodeState.data.state,
			});
			setFormData({
				...formData,
				area: getAreaByPincodeState.data.area,
				city: getAreaByPincodeState.data.city,
				state: getAreaByPincodeState.data.state,
			});
		}
		getAreaByPincodeReset();
	}, [getAreaByPincodeState.apiState]);

	useEffect(() => {
		if (formData.pincode && formData.pincode.length === 6) {
			getAreaByPincode({ pincode: formData.pincode });
		}
	}, [formData.pincode]);

	useEffect(() => {
		if (addEmployerState.apiState === 'success') {
			addEmployerReset();
			setTriggerUpdate(false);
			navigate('/employers');
		} else if (addEmployerState.apiState === 'error') {
			setTriggerUpdate(false);
			let form_errors = addEmployerState.error;
			if (form_errors && typeof form_errors === 'object') {
				form.setFields(
					Object.keys(form_errors).map((key) => {
						return {
							name: key,
							errors: [
								form_errors[key]
									.replace('`', '')
									.replace('`', '')
									.replace('Path', '')
									.replace('_', ' '),
							],
						};
					}),
				);
			} else {
				console.error(
					'Field errors are not available or not an object',
					form_errors,
				);
			}

			notification.error({
				message: 'Unable to Add Employer',
			});
			addEmployerReset();
		}

		setTriggerUpdate(false);
	}, [addEmployerState.apiState]);

	useEffect(() => {
		if (updateEmployerState.apiState === 'success') {
			updateEmployerReset();
			setLoading(false);
			setTriggerUpdate(false);

			navigate('/employers');
		} else if (updateEmployerState.apiState === 'error') {
			setTriggerUpdate(false);

			let form_errors = updateEmployerState.error;

			if (form_errors && typeof form_errors === 'object') {
				form.setFields(
					Object.keys(form_errors).map((key) => {
						return {
							name: key,
							errors: [
								form_errors[key]
									.replace('`', '')
									.replace('`', '')
									.replace('Path', '')
									.replace('_', ' '),
							],
						};
					}),
				);
			} else {
				console.error(
					'Field errors are not available or not an object',
					form_errors,
				);
			}

			notification.error({
				message: updateEmployerState.message,
			});
			updateEmployerReset();
		}
		if (updateEmployerState.apiState === 'loading') {
			setLoading(true);
		}
		setTriggerUpdate(false);
	}, [updateEmployerState.apiState]);

	useEffect(() => {
		if (getIndustriesState.apiState === 'success') {
			setIndustry(getIndustriesState.data.all_industries);
			if (getIndustriesState.data.all_industries.length > 0) {
				const industries = industry.map((ind) => {
					return {
						value: ind.id,
						label: ind.industry_name,
						type: 'industry',
					};
				});
				setIndustryOptions(industries);
			}
		}
	}, [getIndustriesState.apiState, industry]);

	// functions
	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const getDocumentObject = async (document_path, folder_name) => {
		let url = await getSignedUrlForGetObject(
			'employers',
			document_path,
			folder_name,
		);
		let file_name = getFileNameFromDocPath(document_path);
		return {
			name: file_name,
			url: url,
			status: 'done',
		};
	};

	const setAllUploadedDocuments = async (data) => {
		const documentPromises = uploadedDocuments.map(async (document) => {
			try {
				const document_path = data[document.key];
				const document_object = await getDocumentObject(
					document_path,
					data.s3_folder_name,
				);
				document.name = document_object.name;
				document.url = document_object.url;
				document.status = document_object.status;
				return document;
			} catch (error) {
				console.log('Try and catch error => ', error.message);
				return error;
			}
		});
		await Promise.all(documentPromises);
		setDataLoaded(true);
	};

	const uploadFiles = async (currentId) => {
		// keep document paths in an object
		let documentFields = {};
		let allUploadsSuccessful = true; // Track the success status of all uploads
		const s3FolderName = formData.s3_folder_name
			? formData.s3_folder_name
			: uuidv4();

		// Create an array of promises
		if (documentsToUpload.length > 0) {
			await Promise.all(
				documentsToUpload.map(async (document) => {
					const {
						fieldName,
						file,
						onError,
						onSuccess,
						onProgress,
						fileName,
					} = document;
					// Skip the document if it's already uploaded
					if (uploadedDocumentsToS3[fieldName]) {
						return;
					}
					let folder_name = `employers`;
					let file_name = fileName;
					let get_ext = file.name.split('.');
					let ext = get_ext[get_ext.length - 1];

					try {
						const res = await axios.get(
							`${config.api.base_url}/get-signed-url?action=putObject&folder_name=${folder_name}&file_name=${file_name}&ext=${ext}&id=${s3FolderName}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(res.data.data, file, {
							onUploadProgress: ({ total, loaded }) => {
								onProgress(
									{
										percent: Math.round(
											(loaded / total) * 100,
										).toFixed(0),
									},
									file,
								);
							},
						});

						if (result.status === 200) {
							let documentPath = `/${folder_name}/${s3FolderName}/${file_name}.${ext}`;
							// update document paths
							documentFields = {
								...documentFields,
								[fieldName]: documentPath,
							};

							setFormData((prev) => ({
								...prev,
								[fieldName]: documentPath,
							}));
							onSuccess(result, file);

							setUploadedDocumentsToS3((prevState) => ({
								...prevState,
								[fieldName]: documentPath,
							}));
						} else {
							allUploadsSuccessful = false;
						}
					} catch (error) {
						console.log('Try and catch error => ', error.message);
						notification.error({
							message: `Unable to upload file - ${fieldName}`,
						});
						allUploadsSuccessful = false;
					}
				}),
			);
			allUploadsSuccessful = true;
		}

		// // set document fields, id in form data and trigger upload
		// setFormData({ ...formData, ...documentFields, id: currentId });
		if (allUploadsSuccessful) {
			setFormData((prevFormData) => ({
				...prevFormData,
				...documentFields,
				s3_folder_name: s3FolderName,
			}));

			setTriggerUpdate(true);
			allUploadsSuccessful = false;
		}
	};

	useEffect(() => {
		if (triggerUpdate) {
			if (id) {
				updateEmployer({ ...formData, id });
			} else {
				setTriggerUpdate(false);
				addEmployer(formData);
			}
		}
		setTriggerUpdate(false);
	}, [triggerUpdate]);

	const handleIndustriesSearch = (value) => {
		if (value.length > 3)
			getIndustries({
				search: value,
			});
		else if (value.length === 0) getIndustries({});
	};

	const handleSignedByBothChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
			tenure_of_agreement: 1,
			agreement_expiry_date: '',
			agreement_document: '',
			date_of_agreement: '',
		});
		// If you are using Ant Design Form, update the fields as well
		form.setFieldsValue({
			tenure_of_agreement: 1,
			agreement_expiry_date: '',
			agreement_document: '',
			date_of_agreement: '',
		});

		// Remove the file from documentsToUpload state
		const updatedDocuments = documentsToUpload.filter(
			(item) => item.fieldName !== 'agreement_document',
		);
		setDocumentsToUpload(updatedDocuments);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleCinChange = (e) => {
		const { name, value } = e.target;
		if (value) {
			setFormData({
				...formData,
				[name]: value,
			});
		} else {
			setFormData({
				...formData,
				[name]: null,
			});
		}
	};

	const handleSelectChange = (value, name) => {
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSpocChange = (e) => {
		const { name, id, value } = e.target;
		const [fieldIndex, fieldName] = name.split(',');

		setFormData((prevState) => {
			const updatedSpocs = [...prevState.spocs];
			updatedSpocs[fieldIndex] = {
				...updatedSpocs[fieldIndex],
				[fieldName]: value,
				spoc_level: parseInt(fieldIndex) + 1,
			};
			return { ...prevState, spocs: updatedSpocs };
		});
	};

	const removeSpocFormData = (index) => {
		setFormData((prevState) => {
			const updatedSpocs = [...prevState.spocs];
			updatedSpocs.splice(index, 1);

			return {
				...prevState,
				spocs: updatedSpocs,
			};
		});
	};

	const handleStatusChange = (value) => {
		setFormData({
			...formData,
			is_active: value,
		});
	};

	const handleDateOfAgreementChanged = (date) => {
		setFormData({
			...formData,
			date_of_agreement: date.format('YYYY-MM-DD'),
		});
	};

	const handleIndustySelect = (e, value) => {
		setSelectedIndustry([value.value]);
	};

	const handlePrefixChange = (value) => {
		setFormData({
			...formData,
			prefix: value,
		});
		form.setFieldsValue({
			...form.getFieldsValue(),
		});
	};

	const [maxExpiryDate, setMaxExpiryDate] = useState(
		moment().add(1, 'months'),
	);

	useEffect(() => {
		const value = formData.tenure_of_agreement;
		const maxDate =
			formData.prefix === 'Years'
				? moment().add(value, 'years')
				: moment().add(value, 'months');
		setMaxExpiryDate(maxDate);
	}, [formData.tenure_of_agreement, formData.prefix]);
	const disabledDate = (current) => {
		return current && current > maxExpiryDate.endOf('day');
	};

	const handleTenureInputChange = (e) => {
		const value = Number(e.target.value);
		const tenureInMonths = formData.prefix === 'Years' ? value * 12 : value;
		setFormData({ ...formData, tenure_of_agreement: value });
	};

	//calculate the expiry date
	function calculateExpiryDate(
		date_of_agreement,
		tenure_of_agreement,
		prefix,
	) {
		if (!date_of_agreement || !tenure_of_agreement) {
			return null; // Handle cases where the required data is missing
		}

		const startDate = new Date(date_of_agreement);
		let expiryDate;
		const integerPart = Math.floor(tenure_of_agreement);
		const fractionalPart = tenure_of_agreement - integerPart;
		if (prefix === 'Years') {
			// Add years to the start date
			expiryDate = new Date(
				startDate.setFullYear(startDate.getFullYear() + integerPart),
			);

			// Add fractional part as months (1 year = 12 months)
			if (fractionalPart > 0) {
				expiryDate = new Date(
					expiryDate.setMonth(
						expiryDate.getMonth() + fractionalPart * 12,
					),
				);
			}
		} else if (prefix === 'Months') {
			// Add months to the start date
			expiryDate = new Date(
				startDate.setMonth(startDate.getMonth() + integerPart),
			);
			console.log('calculateExpiryDate in else if');

			// Add fractional part as days (approximation: 1 month = 30 days)
			if (fractionalPart > 0) {
				expiryDate = new Date(
					expiryDate.setDate(
						expiryDate.getDate() + fractionalPart * 30,
					),
				);
			}
		}
		// if (prefix === 'Years') {
		// 	// Add years to the start date
		// 	expiryDate = new Date(
		// 		startDate.setFullYear(
		// 			startDate.getFullYear() + tenure_of_agreement,
		// 		),
		// 	);
		// } else if (prefix === 'Months') {
		// 	// Add months to the start date
		// 	expiryDate = new Date(
		// 		startDate.setMonth(startDate.getMonth() + tenure_of_agreement),
		// 	);
		// }
		const formattedDate = moment(expiryDate).format('YYYY-MM-DD');

		setFormData({
			...formData,
			agreement_expiry_date: formattedDate,
		});
		form.setFieldsValue({
			...form.getFieldsValue(),
			agreement_expiry_date: moment(expiryDate),
		});
		// return expiryDate;
	}

	useEffect(() => {
		setFormData({
			...formData,
			agreement_expiry_date: '',
			// tenure_of_agreement: 1,
		});
		form.setFieldsValue({
			...form.getFieldsValue(),
			agreement_expiry_date: null,
			// tenure_of_agreement: 1,
		});
		// Calculate the expiry date whenever date_of_agreement, tenure_of_agreement, or prefix changes
		if (formData.date_of_agreement && formData.tenure_of_agreement) {
			calculateExpiryDate(
				formData.date_of_agreement,
				formData.tenure_of_agreement,
				formData.prefix,
			);
		}
	}, [
		formData.date_of_agreement,
		formData.tenure_of_agreement,
		formData.prefix,
	]);

	const handleDateChanged = (date, name) => {
		if (date) {
			date.format('YYYY-MM-DD');
		}
		setFormData({
			...formData,
			[name]: date,
		});
	};

	return (
		<>
			{id && !modulePermissions.sub_modules.includes('view-employers') ? (
				<>
					{/* On Edit but not have view access */}

					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<Button
									style={{ fontSize: '18px' }}
									className="btn gilroy-bold"
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
								>
									Employers
								</Button>
							</Col>
						}
					/>

					<div className="pageWrapper pt-0">
						<Card className="card">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>Unauthorized to access Employers</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</Card>
					</div>
				</>
			) : !id &&
			  !modulePermissions.sub_modules.includes('add-employers') ? (
				<>
					{/* On Add but not have add access  */}
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<Button
									style={{ fontSize: '18px' }}
									className="btn gilroy-bold"
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
								>
									Add New Employer
								</Button>
							</Col>
						}
					/>
					<div className="pageWrapper pt-0">
						<Card className="card">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>Unauthorized to add Employer</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</Card>
					</div>
				</>
			) : (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={21}
								xl={21}
								className="headerHide"
							>
								<Button
									style={{ fontSize: '18px', float: 'left' }}
									className="btn gilroy-bold mr-3 mb-0"
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
								></Button>
								<h1 className="gilroy-bold mb-0">{title}</h1>
							</Col>
						}
					/>
					<div className="mainContent">
						<div className="pageWrapper">
							{dataLoaded && (
								<Form
									form={form}
									layout="vertical"
									onFinish={uploadFiles}
									initialValues={formData}
								>
									<Row gutter={[30, 0]}>
										<Col
											xs={{ span: 24, order: 2 }}
											sm={{ span: 24, order: 2 }}
											md={{ span: 16, order: 1 }}
										>
											<Row gutter={[30, 0]}>
												<Col xs={24} sm={24}>
													<Form.Item
														label="Organization Name"
														name="organization_name"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter organization name',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="organization_name"
															className="searchInputBrder removeBg"
															size="large"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24}>
													<Form.Item
														label="Organization Address"
														name="organization_address"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter organization address',
															},
														]}
													>
														<TextArea
															onKeyDown={
																handleKeyDown
															}
															name="organization_address"
															className="removeBg"
															placeholder="Enter organization address"
															rows={3}
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Pincode"
														name="pincode"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter pincode',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="pincode"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter Pincode"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Area"
														name="area"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter area',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="area"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter Area"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												{/* {id && ( */}
												<Col xs={24} sm={12}>
													<Form.Item
														label="City"
														name="city"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter city',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="city"
															label="City"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter City"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												{/* // )} */}
												<Col xs={24} sm={12}>
													<Form.Item
														label="State"
														name="state"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter state',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="state"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter State"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Mobile Number"
														name="mobile_number"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter mobile number',
															},
															{
																validator:
																	validateMobileNumber,
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="mobile_number"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter Mobile Number"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												{/* {id && ( */}
												<Col xs={24} sm={12}>
													<Form.Item
														label="Email ID"
														name="email_address"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter email adress',
															},
															{
																type: 'email',
																message:
																	'Please enter a valid email address',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="email_address"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter Email ID"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												{/* )} */}
												<Col xs={24} sm={12}>
													<Form.Item
														label="Minimum Retention Period (Days)"
														name="minimum_retention_period"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter minimum retention period',
															},
															{
																validator: (
																	rule,
																	value,
																) => {
																	if (
																		value >=
																		1
																	) {
																		return Promise.resolve();
																	} else {
																		return Promise.reject(
																			'Days must be greater than or equal to 1.',
																		);
																	}
																},
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															type="number"
															name="minimum_retention_period"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter Minimum Retention Period"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Status"
														name="is_active"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter status',
															},
														]}
													>
														<Select
															onSelect={
																handleStatusChange
															}
															defaultValue={true}
															size="large"
														>
															<Option
																value={true}
															>
																Active
															</Option>
															<Option
																value={false}
															>
																Inactive
															</Option>
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														name="industry"
														label="Industry"
														className="formLable"
														validateFirst={true}
														validateTrigger="onChange"
														rules={[
															{
																required: true,
																message:
																	'Please select industry',
															},
														]}
													>
														<Select
															showSearch
															size="large"
															className="removeBg"
															onChange={(
																value,
																options,
															) =>
																handleSelectChange(
																	value,
																	'industry',
																)
															}
															filterOption={
																filterByLabel
															}
															onSelect={
																handleIndustySelect
															}
															onSearch={
																handleIndustriesSearch
															}
															value={
																formData.industry
																	? formData.industry
																	: 'Select Industry'
															}
															options={
																industryOptions
															}
															placeholder="Select Industry"
														/>
													</Form.Item>
												</Col>
												{/* {id && ( */}
												<Col xs={24} sm={12}>
													<Form.Item
														label="Legal Entity"
														name="legal_entity"
														className="formLable"
														rules={[
															{
																required: true,
																message:
																	'Please enter Legal Entity',
															},
														]}
													>
														<Select
															name="legal_entity"
															size="large"
															className="removeBg"
															onChange={(
																value,
																options,
															) =>
																handleSelectChange(
																	value,
																	'legal_entity',
																)
															}
														>
															{legalEntityList.map(
																(
																	legal_entity,
																) => {
																	return (
																		<Option
																			key={
																				legal_entity.id
																			}
																			value={
																				legal_entity.id
																			}
																		>
																			{
																				legal_entity.name
																			}
																		</Option>
																	);
																},
															)}
														</Select>
													</Form.Item>
												</Col>
												{/* )} */}
											</Row>

											<Form.List
												name="spocs"
												initialValue={formData.spocs}
											>
												{(fields, { add, remove }) => (
													<>
														{fields.map(
															(
																{
																	key,
																	name,
																	...restField
																},
																index,
															) => (
																<Card className="formCard my-5">
																	<Row
																		gutter={[
																			30,
																			0,
																		]}
																		key={
																			index
																		}
																	>
																		{index !==
																			0 && (
																			<Col
																				xs={
																					24
																				}
																				align="right"
																			>
																				<div className="">
																					<TabBtnNoBg
																						type="button"
																						disabled={
																							index ==
																							0
																						}
																						onClick={() => {
																							remove(
																								name,
																							);
																							removeSpocFormData(
																								index,
																							);
																						}}
																					>
																						<DeleteOutlined />
																					</TabBtnNoBg>
																				</div>
																			</Col>
																		)}

																		<Col
																			xs={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<Form.Item
																				{...restField}
																				name={[
																					name,
																					'spoc_name',
																				]}
																				label="SPOC Person Name"
																				className="formLable"
																				rules={[
																					{
																						required:
																							index ==
																							0,
																						message:
																							'Please enter SPOC Person Name',
																					},
																				]}
																			>
																				<Input
																					onKeyDown={
																						handleKeyDown
																					}
																					id={
																						index
																					}
																					name={[
																						name,
																						'spoc_name',
																					]}
																					onChange={
																						handleSpocChange
																					}
																					size="large"
																					className="searchInputBrder removeBg"
																					placeholder="SPOC Person Name"
																				/>
																			</Form.Item>
																		</Col>
																		<Col
																			xs={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<Form.Item
																				{...restField}
																				name={[
																					name,
																					'spoc_designation',
																				]}
																				label="SPOC Person Designation"
																				className="formLable"
																				rules={[
																					{
																						required:
																							index ==
																							0,
																						message:
																							'Please enter SPOC Person Designation',
																					},
																				]}
																			>
																				<Input
																					onKeyDown={
																						handleKeyDown
																					}
																					id={
																						index
																					}
																					name={[
																						name,
																						'spoc_designation',
																					]}
																					onChange={
																						handleSpocChange
																					}
																					size="large"
																					className="searchInputBrder removeBg"
																					placeholder="SPOC Person Designation"
																				/>
																			</Form.Item>
																		</Col>
																		<Col
																			xs={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<Form.Item
																				{...restField}
																				name={[
																					name,
																					'spoc_mobile_number',
																				]}
																				label="SPOC Mobile Number"
																				className="formLable"
																				rules={[
																					{
																						required:
																							index ==
																							0,
																						message:
																							'Please enter SPOC Person Mobile Number',
																					},
																					{
																						validator:
																							validateMobileNumber,
																					},
																				]}
																			>
																				<Input
																					onKeyDown={
																						handleKeyDown
																					}
																					id={
																						index
																					}
																					name={[
																						name,
																						'spoc_mobile_number',
																					]}
																					onChange={
																						handleSpocChange
																					}
																					size="large"
																					className="searchInputBrder removeBg"
																					placeholder="SPOC Mobile Number"
																				/>
																			</Form.Item>
																		</Col>
																		<Col
																			xs={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<Form.Item
																				{...restField}
																				name={[
																					name,
																					'spoc_email_address',
																				]}
																				label="SPOC Email ID"
																				className="formLable"
																				rules={[
																					{
																						required:
																							index ==
																							0,
																						message:
																							'Please enter SPOC Person Email Address',
																					},
																					{
																						type: 'email',
																						message:
																							'Please enter a valid email address',
																					},
																				]}
																			>
																				<Input
																					onKeyDown={
																						handleKeyDown
																					}
																					id={
																						index
																					}
																					name={[
																						name,
																						'spoc_email_address',
																					]}
																					onChange={
																						handleSpocChange
																					}
																					size="large"
																					className="searchInputBrder removeBg"
																					placeholder="SPOC Email ID"
																				/>
																			</Form.Item>
																		</Col>
																	</Row>
																	<Row>
																		<Col
																			xs={
																				24
																			}
																			sm={
																				24
																			}
																			className="mt-5"
																		>
																			<SubmitNoBgBtn
																				type="button"
																				disabled={
																					fields.length ===
																					3
																				}
																				style={{
																					color: Theme
																						.colors
																						.bgButton,
																					borderColor:
																						Theme
																							.colors
																							.bgButton,
																					padding:
																						'10px 15px',
																					width: 'auto',
																				}}
																				onClick={() =>
																					add()
																				}
																			>
																				Add
																				SPOC{' '}
																				<PlusOutlined />
																			</SubmitNoBgBtn>
																		</Col>
																	</Row>
																</Card>
															),
														)}
													</>
												)}
											</Form.List>
											<Row gutter={[30, 0]}>
												<Col xs={24} sm={12}>
													<Form.Item
														label="MSME Registration Number"
														name="msme_registration_number"
														className="formLable"
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="msme_registration_number"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter MSME Registration Number"
															onChange={
																handleInputChange
															}
															onInput={(e) =>
																(e.target.value =
																	e.target.value
																		.toUpperCase()
																		.replace(
																			/\s/g,
																			'',
																		))
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Upload MSME Registration Certificate"
														name="msme_registration_document"
														className="formLable"
														getValueFromEvent={
															normFile
														}
													>
														<Upload
															maxCount={1}
															className="uploadButton"
															onRemove={(
																file,
															) => {
																const updatedDocuments =
																	documentsToUpload.filter(
																		(
																			item,
																		) =>
																			item.fieldName !==
																			'msme_registration_document',
																	);
																setDocumentsToUpload(
																	updatedDocuments,
																);
															}}
															customRequest={(
																options,
															) => {
																const existingIndex =
																	documentsToUpload.findIndex(
																		(
																			item,
																		) =>
																			item.fieldName ===
																			'msme_registration_document',
																	);

																if (
																	existingIndex !==
																	-1
																) {
																	// Update existing object
																	const updatedDocuments =
																		[
																			...documentsToUpload,
																		];
																	updatedDocuments[
																		existingIndex
																	] = {
																		...options,
																		fileName:
																			'MSME',
																		fieldName:
																			'msme_registration_document',
																	};
																	setDocumentsToUpload(
																		updatedDocuments,
																	);
																} else {
																	// Add new object
																	setDocumentsToUpload(
																		[
																			...documentsToUpload,
																			{
																				...options,
																				fileName:
																					'MSME',
																				fieldName:
																					'msme_registration_document',
																			},
																		],
																	);
																}

																options.onSuccess();
															}}
															defaultFileList={
																id
																	? uploadedDocuments.find(
																			(
																				doc,
																			) =>
																				doc.key ===
																				'msme_registration_document',
																	  ).name
																		? [
																				uploadedDocuments.find(
																					(
																						doc,
																					) =>
																						doc.key ===
																						'msme_registration_document',
																				),
																		  ]
																		: false
																	: false
															}
														>
															<Button
																block
																style={{
																	textAlign:
																		'left',
																	width: '100%',
																}}
															>
																Upload
																<UploadOutlined
																	style={{
																		float: 'right',
																		fontSize:
																			'20px',
																	}}
																/>
															</Button>
														</Upload>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="PAN Number"
														name="pan_number"
														className="formLable"
														rules={[
															{
																required: false,
																message:
																	'Please enter pan number',
															},
															{
																pattern:
																	/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
																message:
																	'PAN number is invalid',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="pan_number"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter PAN Number"
															onChange={
																handleInputChange
															}
															onInput={(e) =>
																(e.target.value =
																	e.target.value
																		.toUpperCase()
																		.replace(
																			/\s/g,
																			'',
																		))
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Upload PAN Copy"
														name="pan_document"
														className="formLable"
														getValueFromEvent={
															normFile
														}
														rules={[
															{
																required: false,
																message:
																	'Please upload pan document',
															},
														]}
													>
														<Upload
															maxCount={1}
															className="uploadButton"
															onRemove={(
																file,
															) => {
																const updatedDocuments =
																	documentsToUpload.filter(
																		(
																			item,
																		) =>
																			item.fieldName !==
																			'pan_document',
																	);
																setDocumentsToUpload(
																	updatedDocuments,
																);
															}}
															customRequest={(
																options,
															) => {
																const existingIndex =
																	documentsToUpload.findIndex(
																		(
																			item,
																		) =>
																			item.fieldName ===
																			'pan_document',
																	);

																if (
																	existingIndex !==
																	-1
																) {
																	// Update existing object
																	const updatedDocuments =
																		[
																			...documentsToUpload,
																		];
																	updatedDocuments[
																		existingIndex
																	] = {
																		...options,
																		fileName:
																			'PAN',

																		fieldName:
																			'pan_document',
																	};
																	setDocumentsToUpload(
																		updatedDocuments,
																	);
																} else {
																	// Add new object
																	setDocumentsToUpload(
																		[
																			...documentsToUpload,
																			{
																				...options,
																				fileName:
																					'PAN',

																				fieldName:
																					'pan_document',
																			},
																		],
																	);
																}

																options.onSuccess();
															}}
															defaultFileList={
																id
																	? uploadedDocuments.find(
																			(
																				doc,
																			) =>
																				doc.key ===
																				'pan_document',
																	  ).name
																		? [
																				uploadedDocuments.find(
																					(
																						doc,
																					) =>
																						doc.key ===
																						'pan_document',
																				),
																		  ]
																		: false
																	: false
															}
														>
															<Button
																block
																style={{
																	textAlign:
																		'left',
																}}
															>
																Upload
																<UploadOutlined
																	style={{
																		float: 'right',
																		fontSize:
																			'20px',
																	}}
																/>
															</Button>
														</Upload>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="CIN"
														name="cin_number"
														className="formLable"
														rules={[
															{
																required: false,
																message:
																	'Please enter cin',
															},
															{
																pattern:
																	/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
																message:
																	'CIN number is invalid',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="cin_number"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter CIN"
															onChange={
																handleCinChange
															}
															onInput={(e) =>
																(e.target.value =
																	e.target.value
																		.toUpperCase()
																		.replace(
																			/\s/g,
																			'',
																		))
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Upload CIN Copy"
														name="cin_document"
														className="formLable"
														getValueFromEvent={
															normFile
														}
														rules={[
															{
																required: false,
																message:
																	'Please upload cin document',
															},
														]}
													>
														<Upload
															maxCount={1}
															className="uploadButton"
															onRemove={(
																file,
															) => {
																const updatedDocuments =
																	documentsToUpload.filter(
																		(
																			item,
																		) =>
																			item.fieldName !==
																			'cin_document',
																	);
																setDocumentsToUpload(
																	updatedDocuments,
																);
															}}
															customRequest={(
																options,
															) => {
																const existingIndex =
																	documentsToUpload.findIndex(
																		(
																			item,
																		) =>
																			item.fieldName ===
																			'cin_document',
																	);

																if (
																	existingIndex !==
																	-1
																) {
																	// Update existing object
																	const updatedDocuments =
																		[
																			...documentsToUpload,
																		];
																	updatedDocuments[
																		existingIndex
																	] = {
																		...options,
																		fileName:
																			'CIN',

																		fieldName:
																			'cin_document',
																	};
																	setDocumentsToUpload(
																		updatedDocuments,
																	);
																} else {
																	// Add new object
																	setDocumentsToUpload(
																		[
																			...documentsToUpload,
																			{
																				...options,
																				fileName:
																					'CIN',

																				fieldName:
																					'cin_document',
																			},
																		],
																	);
																}

																options.onSuccess();
															}}
															defaultFileList={
																id
																	? uploadedDocuments.find(
																			(
																				doc,
																			) =>
																				doc.key ===
																				'cin_document',
																	  ).name
																		? [
																				uploadedDocuments.find(
																					(
																						doc,
																					) =>
																						doc.key ===
																						'cin_document',
																				),
																		  ]
																		: false
																	: false
															}
														>
															<Button
																block
																style={{
																	textAlign:
																		'left',
																}}
															>
																Upload
																<UploadOutlined
																	style={{
																		float: 'right',
																		fontSize:
																			'20px',
																	}}
																/>
															</Button>
														</Upload>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="GSTIN"
														name="gstin_number"
														className="formLable"
														rules={[
															{
																required: false,
																message:
																	'Please enter gstin',
															},
															{
																pattern:
																	/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
																message:
																	'GSTIN number is invalid',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															name="gstin_number"
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter GSTIN"
															onChange={
																handleInputChange
															}
															onInput={(e) =>
																(e.target.value =
																	e.target.value
																		.toUpperCase()
																		.replace(
																			/\s/g,
																			'',
																		))
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12}>
													<Form.Item
														label="Upload GSTIN Copy"
														name="gstin_document"
														getValueFromEvent={
															normFile
														}
														className="formLable"
														rules={[
															{
																required: false,
																message:
																	'Please upload gstin document',
															},
														]}
													>
														<Upload
															maxCount={1}
															className="uploadButton"
															onRemove={(
																file,
															) => {
																const updatedDocuments =
																	documentsToUpload.filter(
																		(
																			item,
																		) =>
																			item.fieldName !==
																			'gstin_document',
																	);
																setDocumentsToUpload(
																	updatedDocuments,
																);
															}}
															customRequest={(
																options,
															) => {
																const existingIndex =
																	documentsToUpload.findIndex(
																		(
																			item,
																		) =>
																			item.fieldName ===
																			'gstin_document',
																	);

																if (
																	existingIndex !==
																	-1
																) {
																	// Update existing object
																	const updatedDocuments =
																		[
																			...documentsToUpload,
																		];
																	updatedDocuments[
																		existingIndex
																	] = {
																		...options,
																		fileName:
																			'GSTIN',
																		fieldName:
																			'gstin_document',
																	};
																	setDocumentsToUpload(
																		updatedDocuments,
																	);
																} else {
																	// Add new object
																	setDocumentsToUpload(
																		[
																			...documentsToUpload,
																			{
																				...options,
																				fileName:
																					'GSTIN',
																				fieldName:
																					'gstin_document',
																			},
																		],
																	);
																}

																options.onSuccess();
															}}
															defaultFileList={
																id
																	? uploadedDocuments.find(
																			(
																				doc,
																			) =>
																				doc.key ===
																				'gstin_document',
																	  ).name
																		? [
																				uploadedDocuments.find(
																					(
																						doc,
																					) =>
																						doc.key ===
																						'gstin_document',
																				),
																		  ]
																		: false
																	: false
															}
														>
															<Button
																block
																style={{
																	textAlign:
																		'left',
																}}
															>
																Upload
																<UploadOutlined
																	style={{
																		float: 'right',
																		fontSize:
																			'20px',
																	}}
																/>
															</Button>
														</Upload>
													</Form.Item>
												</Col>{' '}
												<Col xs={24} sm={12} lg={12}>
													<Form.Item
														label="Signed Agreement Received"
														name="signed_by_both"
														className="formLable mb-0"
														rules={[
															{
																required: true,
																message:
																	'Please choose signed agreement received',
															},
														]}
													>
														<Radio.Group
															name="signed_by_both"
															defaultValue={true}
															className="customRadio"
															onChange={
																handleInputChange
															}
														>
															<Radio value={true}>
																Yes
															</Radio>
															<Radio
																value={false}
															>
																No
															</Radio>
														</Radio.Group>
													</Form.Item>
												</Col>{' '}
												<Col xs={24} sm={12} lg={12}>
													<Form.Item
														label="Date of Agreement"
														name="date_of_agreement"
														className="formLable"
														rules={[
															{
																required:
																	form.getFieldValue(
																		'signed_by_both',
																	)
																		? true
																		: false,

																message:
																	'Please select date of agreement',
															},
														]}
													>
														<DatePicker
															name="date_of_agreement"
															format="DD/MM/YYYY"
															placeholder="DD/MM/YYYY"
															size="large"
															onChange={(
																date,
															) => {
																handleDateChanged(
																	date,
																	'date_of_agreement',
																);
															}}
															style={{
																width: '100%',
															}}
														/>
													</Form.Item>
												</Col>
												{/* <Row gutter={[30, 0]}> */}
												<Col xs={24} sm={12} lg={12}>
													<Form.Item
														name="tenure_of_agreement"
														label="Tenure of Agreement"
														className="formLable mb-0"
														rules={[
															{
																required:
																	form.getFieldValue(
																		'signed_by_both',
																	)
																		? true
																		: false,
																message:
																	'Please enter tenure of agreement',
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															type="number"
															min={1}
															name="tenure_of_agreement"
															className="removeBg w-100"
															size="large"
															placeholder="Enter Tenure of Agreement"
															onChange={
																handleTenureInputChange
															}
															addonBefore={
																<Select
																	style={{
																		width: 100,
																	}}
																	onChange={
																		handlePrefixChange
																	}
																	defaultValue="Months"
																>
																	<Option value="Months">
																		Months
																	</Option>
																	<Option value="Years">
																		Years
																	</Option>
																</Select>
															}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} lg={12}>
													<Form.Item
														name="agreement_expiry_date"
														label="Agreement Expiry"
														className="formLable mb-0"
														rules={[
															{
																required:
																	form.getFieldValue(
																		'signed_by_both',
																	)
																		? true
																		: false,
																message:
																	'Please select agreement expiry date',
															},
														]}
													>
														<DatePicker
															className="searchInputBrder removeBg w-100"
															size="large"
															format="DD/MM/YYYY"
															placeholder="DD/MM/YYYY"
															disabledDate={
																disabledDate
															}
															onChange={(
																date,
															) => {
																handleDateChanged(
																	date,
																	'agreement_expiry_date',
																);
															}}
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col xs={24}>
													<Form.Item
														label="Agreement"
														name="agreement_document"
														className="formLable"
														getValueFromEvent={
															normFile
														}
														rules={[
															{
																required:
																	form.getFieldValue(
																		'signed_by_both',
																	)
																		? true
																		: false,
																message:
																	'Please upload agreement',
															},
														]}
													>
														<Dragger
															maxCount={1}
															onRemove={(
																file,
															) => {
																const updatedDocuments =
																	documentsToUpload.filter(
																		(
																			item,
																		) =>
																			item.fieldName !==
																			'agreement_document',
																	);
																setDocumentsToUpload(
																	updatedDocuments,
																);
																setFormData({
																	...formData,
																	agreement_document:
																		'',
																});
															}}
															customRequest={(
																options,
															) => {
																const existingIndex =
																	documentsToUpload.findIndex(
																		(
																			item,
																		) =>
																			item.fieldName ===
																			'agreement_document',
																	);

																if (
																	existingIndex !==
																	-1
																) {
																	// Update existing object
																	const updatedDocuments =
																		[
																			...documentsToUpload,
																		];
																	updatedDocuments[
																		existingIndex
																	] = {
																		...options,
																		fileName:
																			'Agreement',
																		fieldName:
																			'agreement_document',
																	};
																	setDocumentsToUpload(
																		updatedDocuments,
																	);
																} else {
																	// Add new object
																	setDocumentsToUpload(
																		[
																			...documentsToUpload,
																			{
																				...options,
																				fileName:
																					'Agreement',
																				fieldName:
																					'agreement_document',
																			},
																		],
																	);
																}

																options.onSuccess();
															}}
															defaultFileList={
																id
																	? uploadedDocuments.find(
																			(
																				doc,
																			) =>
																				doc.key ===
																				'agreement_document',
																	  ).name
																		? [
																				uploadedDocuments.find(
																					(
																						doc,
																					) =>
																						doc.key ===
																						'agreement_document',
																				),
																		  ]
																		: false
																	: false
															}
															style={{
																background:
																	'rgba(230, 104, 37, 0.05)',
															}}
														>
															<p className="ant-upload-drag-icon">
																<img
																	src="/images/upload-icon.svg"
																	alt=""
																/>
															</p>
															<div className="uploadHeading">
																Drag & drop
																files or{' '}
																<span>
																	Browse
																</span>
															</div>
															<p className="para3">
																Supported
																formates: JPEG,
																PDF
															</p>
														</Dragger>
													</Form.Item>
												</Col>
											</Row>

											{/* </Card> */}
										</Col>

										<Col
											xs={{ span: 24, order: 1 }}
											sm={{ span: 24, order: 1 }}
											md={{ span: 8, order: 2 }}
										>
											{id && (
												<div className="addEmployerBg mb-5">
													<Row gutter={[30, 30]}>
														<Col
															xs={24}
															sm={12}
															md={12}
														>
															<div className="addEmpHeading gilroy-bold">
																{
																	formData.total_jobs
																}
															</div>
															<div className="addEmpPara gilroy-semiBold">
																Total Jobs
															</div>
														</Col>
														<Col
															xs={24}
															sm={12}
															md={12}
														>
															<div className="addEmpHeading gilroy-bold">
																{
																	formData.candidates_hired
																}
															</div>
															<div className="addEmpPara gilroy-semiBold">
																Total Hired
															</div>
														</Col>
													</Row>
													<Row
														gutter={[30, 30]}
														className="mt-5"
													>
														<Col
															xs={24}
															sm={12}
															md={12}
														>
															<div className="addEmpHeading gilroy-bold">
																₹{' '}
																{
																	formData.total_earnings
																}
															</div>
															<div className="addEmpPara gilroy-semiBold">
																Earnings
															</div>
														</Col>
														<Col
															xs={24}
															sm={12}
															md={12}
														>
															<div className="addEmpHeading gilroy-bold">
																₹{' '}
																{
																	formData.pending_invoice
																}
															</div>
															<div className="addEmpPara gilroy-semiBold">
																Pending Inv.
															</div>
														</Col>
														<Col
															span={24}
															align="center"
														>
															<img
																width="100%"
																src="../images/addEmp-img.svg"
																style={{
																	marginBottom:
																		'-15px',
																}}
																alt=""
															/>
														</Col>
													</Row>
												</div>
											)}

											<Card className="formCard my-5">
												<Form.Item
													label="SkillsKonnect Commission"
													name="sourcing_fees_criteria"
													className="formLable mb-0"
													rules={[
														{
															required: true,
															message:
																'Please select skillskonnect commission',
														},
													]}
												>
													<Radio.Group
														name="sourcing_fees_criteria"
														defaultValue="percentage"
														className="customRadio"
														onChange={
															handleInputChange
														}
													>
														<Radio value="percentage">
															Percentage
														</Radio>
														<Radio value="fixed">
															Fixed
														</Radio>
														<Radio value="free">
															Free
														</Radio>
													</Radio.Group>
												</Form.Item>
												{formData.sourcing_fees_criteria ==
												'free' ? (
													<Form.Item
														// label="Amount"
														className="formLable"
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															disabled
															className="searchInputBrder"
															size="large"
															placeholder="Enter Amount"
														/>
													</Form.Item>
												) : formData.sourcing_fees_criteria ==
												  'percentage' ? (
													<Form.Item
														name="percentage_value"
														className="formLable"
														rules={[
															{
																required:
																	formData.sourcing_fees_criteria ==
																	'percentage',
																message:
																	'Please enter percentage value',
															},
															{
																validator: (
																	rule,
																	value,
																) => {
																	if (
																		formData.sourcing_fees_criteria ==
																		'percentage'
																	) {
																		if (
																			value >=
																				1 &&
																			value <=
																				100
																		) {
																			return Promise.resolve();
																		} else {
																			return Promise.reject(
																				'Percentage value must be between 1 and 100.',
																			);
																		}
																	}
																},
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															type="number"
															style={{
																width: '100%',
															}}
															name="percentage_value"
															min={1}
															step={'any'}
															max={100}
															precision={2}
															className="searchInputBrder"
															size="large"
															placeholder="Enter Percentage"
															onChange={
																handleInputChange
															}
														/>
													</Form.Item>
												) : (
													<Form.Item
														name="fixed_value"
														className="formLable"
														rules={[
															{
																required:
																	formData.sourcing_fees_criteria ==
																	'fixed',
																message:
																	'Please enter fixed value',
															},
															{
																validator: (
																	rule,
																	value,
																) => {
																	if (
																		formData.sourcing_fees_criteria ==
																		'fixed'
																	) {
																		if (
																			value >=
																			1
																		) {
																			return Promise.resolve();
																		} else {
																			return Promise.reject(
																				'Fixed value must be equal to or greater than 1.',
																			);
																		}
																	}
																},
															},
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															type="number"
															name="fixed_value"
															min={1}
															precision={2}
															className="searchInputBrder"
															size="large"
															placeholder="Enter Amount"
															onChange={
																handleInputChange
															}
															style={{
																width: '100%',
															}}
														/>
													</Form.Item>
												)}
											</Card>
											{/* {!id && (
												<>
													<Col
														xs={24}
														sm={12}
														lg={24}
														className="mt-5"
													>
														<Form.Item
															label="City"
															name="city"
															className="formLable"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter city',
																},
															]}
														>
															<Input
																onKeyDown={
																	handleKeyDown
																}
																name="city"
																label="City"
																className="searchInputBrder removeBg"
																size="large"
																placeholder="Enter City"
																onChange={
																	handleInputChange
																}
															/>
														</Form.Item>
													</Col>
													<Col
														xs={24}
														sm={12}
														lg={24}
													>
														<Form.Item
															label="Email ID"
															name="email_address"
															className="formLable"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter email adress',
																},
																{
																	type: 'email',
																	message:
																		'Please enter a valid email address',
																},
															]}
														>
															<Input
																onKeyDown={
																	handleKeyDown
																}
																name="email_address"
																className="searchInputBrder removeBg"
																size="large"
																placeholder="Enter Email ID"
																onChange={
																	handleInputChange
																}
															/>
														</Form.Item>
													</Col>
													<Col
														xs={24}
														sm={12}
														lg={24}
													>
														<Form.Item
															label="Legal Entity"
															name="legal_entity"
															className="formLable"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter Legal Entity',
																},
															]}
														>
															<Select
																name="legal_entity"
																size="large"
																className="removeBg"
																onChange={(
																	value,
																	options,
																) =>
																	handleSelectChange(
																		value,
																		'legal_entity',
																	)
																}
															>
																{legalEntityList.map(
																	(
																		legal_entity,
																	) => {
																		return (
																			<Option
																				key={
																					legal_entity.id
																				}
																				value={
																					legal_entity.id
																				}
																			>
																				{
																					legal_entity.name
																				}
																			</Option>
																		);
																	},
																)}
															</Select>
														</Form.Item>
													</Col>
												</>
											)} */}
										</Col>
									</Row>
									<Row>
										<Col xs={24} sm={24} align="left">
											<SubmitNoBgBtn
												className="mr-3"
												type="primary"
											>
												Cancel
											</SubmitNoBgBtn>
											{loading ? (
												<SubmitBtn
													type="primary"
													// htmlType="submit"
												>
													{id
														? 'Saving Employer...'
														: 'Adding Employer...'}
												</SubmitBtn>
											) : (
												<SubmitBtn
													type="primary"
													htmlType="submit"
												>
													{id
														? 'Save Employer'
														: 'Add Employer'}
												</SubmitBtn>
											)}
										</Col>
									</Row>
								</Form>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	addEmployerState: state.addEmployer,
	addEmployerResetState: state.addEmployerReset,
	getEmployerByIdState: state.getEmployerById,
	getEmployerByIdResetState: state.getEmployerByIdReset,
	updateEmployerState: state.updateEmployer,
	updateEmployerResetState: state.updateEmployerReset,
	getSignedURLState: state.getSignedURL,
	getIndustriesState: state.getIndustries,
	getAreaByPincodeState: state.getAreaByPincode,
});

const mapDispatchToProps = (dispatch) => ({
	addEmployer: (params) => dispatch(addEmployerApi(params)),
	addEmployerReset: (params) => dispatch(addEmployerReset(params)),
	getEmployerById: (params) => dispatch(getEmployerByIdApi(params)),
	getEmployerByIdReset: (params) => dispatch(getEmployerByIdReset(params)),
	updateEmployer: (params) => dispatch(updateEmployerApi(params)),
	updateEmployerReset: (params) => dispatch(updateEmployerReset(params)),
	getSignedURL: (params) => dispatch(getSignedURLApi(params)),
	getIndustries: (params) => dispatch(getIndustriesApi(params)),
	getAreaByPincode: (params) => dispatch(getAreaByPincodeApi(params)),
	getAreaByPincodeReset: () => dispatch(getAreaByPincodeReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEmployers);
