import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	Row,
	Col,
	Input,
	Form,
	Upload,
	Select,
	notification,
	InputNumber,
	Card,
	Radio,
	Divider,
	DatePicker,
	Button,
} from 'antd';
import {
	ButtonNoBg,
	SubmitBtn,
	SubmitNoBgBtn,
	TabBtnNoBg,
} from '../../styles/Button';
import {
	PlusOutlined,
	ReloadOutlined,
	UploadOutlined,
	ArrowLeftOutlined,
} from '@ant-design/icons';
import { Theme } from '../../GolbalStyle';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import dayjs from 'dayjs';

//Actions
import {
	getIndustries,
	getIndustriesApi,
} from '../../actions/getIndustriesAction';
import {
	getIndustryCategories,
	getIndustryCategoriesApi,
} from '../../actions/getIndustryCategoriesAction';
import {
	getResellerByIdReset,
	getResellerByIdApi,
} from '../../actions/getResellerByIdAction';
import {
	updateResellerReset,
	updateResellerApi,
} from '../../actions/updateResellerAction';
import { getEmployerOrganizationsListApi } from '../../actions/getEmployerOrganizationsListAction';
import { getResellerOrganizationsListApi } from '../../actions/getResellerOrganizationsListAction';

import {
	addResellerReset,
	addResellerApi,
} from '../../actions/addResellerAction';
import { getCities, getCitiesApi } from '../../actions/getCitiesAction';
import {
	getAreaByPincodeReset,
	getAreaByPincodeApi,
} from '../../actions/getAreaByPincodeAction';

// Others
import HeaderFile from '../../components/Header/HeaderFile';

import {
	organizationTypeList,
	legalEntityList,
	maxDropdownSelections,
	maxLocationSelections,
	preferedCollarList,
	bankAccountTypesList,
	jobSeekerPortal,
} from '../../constants';
import {
	validateMobileNumber,
	getSignedUrlForGetObject,
	getFileNameFromDocPath,
	checkModulePermissions,
	getUserToken,
	capitalizeAndSplitByDash,
	filterByLabel,
	handleKeyDown,
} from '../../utils';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;
const { Dragger } = Upload;

const AddEditResellers = (props) => {
	let modulePermissions = checkModulePermissions('resellers');

	const {
		getIndustries,
		getIndustriesState,
		getIndustryCategories,
		getIndustryCategoriesState,
		getCities,
		getCitiesState,
		getAreaByPincode,
		getAreaByPincodeReset,
		getAreaByPincodeResetState,
		getAreaByPincodeState,
		getResellerById,
		getResellerByIdReset,
		getResellerByIdState,
		getResellerByIdResetState,
		addReseller,
		addResellerReset,
		addResellerState,
		addResellerResetState,
		updateReseller,
		updateResellerReset,
		updateResellerResetState,
		updateResellerState,
		getEmployerOrganizationsList,
		getEmployerOrganizationsListState,
		getResellerOrganizationsList,
		getResellerOrganizationsListState,
	} = props;

	const id = useParams().id;
	const navigate = useNavigate();
	const [selectedResellerType, setSelectedResellerType] =
		useState('organization');
	const [commissionType, setCommissionType] = useState('vendor_commission');
	const formInitial = {
		reseller_type: 'organization',
		first_name: '',
		last_name: '',
		organization_name: '',
		alias_name: '',
		organization_type: [],
		legal_entity: null,
		designation: '',
		email_address: '',
		mobile_number: '',
		communication_mobile_number: '',
		communication_email_address: '',
		pincode: '',
		area: '',
		city: '',
		state: '',
		address: '',
		industries: [],
		preferred_collar_job: '',
		preferred_job_locations: [],
		preferred_job_roles: [],
		team_members: [
			{
				team_member_full_name: '',
				team_member_mobile_number: '',
				team_member_email_address: '',
				team_member_designation: '',
				team_member_role: null,
			},
		],
		commission_type: 'vendor_commission',
		placement_fees_criteria: null,
		placement_fees_fixed_value: null,
		placement_fees_percentage_value: null,
		vendor_commission_criteria: 'percentage',
		vendor_commission_fixed_value: null,
		vendor_commission_percentage_value: null,
		// add_on_commission: {
		// 	add_on_commission_type: 'partner',
		// 	add_on_commission_percentage: null,
		// 	add_on_commission_end_date: new Date(),
		// 	referred_by_organization: '',
		// 	referrer_name: '',
		// },
		agreement_document: '',
		date_of_agreement: '',
		agreement_expiry_date: '',
		tenure_of_agreement: null,
		prefix: 'Months',
		signed_by_both: true,
		cin_number: '',
		cin_document: '',
		gstin_number: '',
		gstin_document: '',
		pan_number: '',
		pan_document: '',
		msme_registration_number: null,
		msme_registration_document: null,
		bank_name: '',
		bank_ifsc: '',
		bank_account_name: '',
		bank_account_number: '',
		bank_account_type: 'savings',
		bank_address: '',
		bank_reference_document: '',
		gajab_portal_name: '',
		onboarded_by_admin: true,
	};
	const [form] = Form.useForm();
	const [formData, setFormData] = useState(formInitial);
	const [documentsToUpload, setDocumentsToUpload] = useState([]);
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const [uploadedDocuments, setUploadedDocuments] = useState(null);
	const [uploadedDocumentsToS3, setUploadedDocumentsToS3] = useState({});
	const [dataLoaded, setDataLoaded] = useState(false);
	// const [updatedTeamMembers, setUpdatedTeamMembers] = useState([]);

	useEffect(() => {
		getIndustries();
		getCities();
		getAreaByPincodeReset();
		if (id) {
			setUploadedDocuments([
				{
					key: 'bank_reference_document',
				},
				{ key: 'msme_registration_document' },
				{ key: 'pan_document' },
				{ key: 'cin_document' },
				{ key: 'gstin_document' },
				{ key: 'agreement_document' },
			]);
			getResellerById({ id: id });
		} else {
			setDataLoaded(true);
			form.setFieldsValue(formData);
		}
	}, []);

	useEffect(() => {
		if (formData.pincode && formData.pincode.length === 6) {
			getAreaByPincode({ pincode: formData.pincode });
		}
	}, [formData.pincode]);

	useEffect(() => {
		if (
			getAreaByPincodeState.apiState === 'success' &&
			getAreaByPincodeState.data
		) {
			form.setFieldsValue({
				...form.getFieldsValue(),
				area: getAreaByPincodeState.data.area,
				city: getAreaByPincodeState.data.city,
				state: getAreaByPincodeState.data.state,
			});
			setFormData({
				...formData,
				area: getAreaByPincodeState.data.area,
				city: getAreaByPincodeState.data.city,
				state: getAreaByPincodeState.data.state,
			});
		}
		getAreaByPincodeReset();
	}, [getAreaByPincodeState.apiState]);
	console.log('team members array', formData.team_members);

	useEffect(() => {
		if (getResellerByIdState.apiState === 'success') {
			setSelectedResellerType(getResellerByIdState.data.reseller_type);
			const fieldNames = Object.keys(formData);
			const updatedData = {};
			fieldNames.forEach((fieldName) => {
				const fieldValue = getResellerByIdState.data[fieldName];
				if (fieldValue !== undefined) {
					updatedData[fieldName] = fieldValue;
					if (
						fieldName === 'date_of_agreement' ||
						fieldName === 'agreement_expiry_date'
					) {
						form.setFieldsValue({
							[fieldName]: dayjs(fieldValue),
						});
					} else if (fieldName === 'preferred_job_roles') {
						form.setFieldsValue({
							[fieldName]: fieldValue.map((item) => item._id),
						});
						updatedData[fieldName] = fieldValue.map(
							(item) => item._id,
						);
					} else {
						form.setFieldsValue({
							[fieldName]: fieldValue,
						});
					}
				}
			});

			setFormData({
				...updatedData,
				...getResellerByIdState.data,
				team_members:
					getResellerByIdState.data?.team_members.length > 0
						? getResellerByIdState.data?.team_members
						: [
								{
									team_member_full_name: '',
									team_member_mobile_number: '',
									team_member_email_address: '',
									team_member_designation: '',
									team_member_role: null,
								},
						  ],

				pincode: getResellerByIdState.data?.pincode.toString(),
				id: getResellerByIdState.data._id,
				s3_folder_name: getResellerByIdState.data.s3_folder_name,
				preferred_job_roles:
					getResellerByIdState.data.preferred_job_roles,
				prefix: 'Months',
			});
			// setUpdatedTeamMembers(getResellerByIdState.data?.team_members);
			setAllUploadedDocuments(getResellerByIdState.data);
		} else if (getResellerByIdState.apiState === 'error') {
			console.log('API Error=>', getResellerByIdState.message);
			notification.error({
				message: 'Unable to get reseller details',
			});
		}
		getResellerByIdReset();
	}, [getResellerByIdState.apiState]);

	// useEffect(() => {
	// 	if (formData.add_on_commission.add_on_commission_type === 'employer') {
	// 		getEmployerOrganizationsList({});
	// 	} else {
	// 		getResellerOrganizationsList({});
	// 	}
	// }, [formData.add_on_commission.add_on_commission_type]);

	// useEffect(() => {
	// 	// when roles dropdown is changed -> remove selected tags not exists in new dropdown options
	// 	if (getIndustryCategoriesState.apiState === 'success') {
	// 		if (formData.preferred_job_roles.length > 0) {
	// 			const updatedRoles = formData.preferred_job_roles.filter(
	// 				(role) => {
	// 					return getIndustryCategoriesState.data.all_categories.some(
	// 						(category) => category.id === role,
	// 					);
	// 				},
	// 			);
	// 			setFormData({ ...formData, preferred_job_roles: updatedRoles });
	// 			form.setFieldsValue({
	// 				...form.getFieldsValue,
	// 				preferred_job_roles: updatedRoles,
	// 			});
	// 		}
	// 	}
	// }, [getIndustryCategoriesState.apiState]);

	useEffect(() => {
		getIndustryCategories({ industries: formData.industries });
	}, [formData.industries]);

	useEffect(() => {
		if (addResellerState.apiState === 'success') {
			addResellerReset();
			navigate('/resellers');
		} else if (addResellerState.apiState === 'error') {
			console.log('API Error=>', addResellerState);
			// Display notification
			notification.error({
				message: addResellerState.message ?? 'Error',
			});

			// Handle field-specific validation errors
			const fieldErrors = addResellerState.error;
			if (fieldErrors && typeof fieldErrors === 'object') {
				const fields = Object.keys(fieldErrors).map((key) => {
					const message = fieldErrors[key]
						.replace(/_/g, ' ')
						.replace(/\b\w/g, (char) => char.toUpperCase());

					return {
						name: key,
						errors: [message],
					};
				});
				form.setFields(fields);
			}
			addResellerReset();
		}
		setTriggerUpdate(false);
	}, [addResellerState.apiState]);

	useEffect(() => {
		if (updateResellerState.apiState === 'success') {
			updateResellerReset();
			navigate('/resellers');
		} else if (updateResellerState.apiState === 'error') {
			console.log('API Error=>', updateResellerState);
			// Display notification
			notification.error({
				message: updateResellerState.message ?? 'Error',
			});

			// Handle field-specific validation errors
			const fieldErrors = updateResellerState.error;
			if (fieldErrors && typeof fieldErrors === 'object') {
				const fields = Object.keys(fieldErrors).map((key) => {
					const message = fieldErrors[key]
						.replace(/_/g, ' ')
						.replace(/\b\w/g, (char) => char.toUpperCase());

					return {
						name: key,
						errors: [message],
					};
				});
				form.setFields(fields);
			}
			updateResellerReset();
		}
		setTriggerUpdate(false);
	}, [updateResellerState.apiState]);

	useEffect(() => {
		if (triggerUpdate) {
			// Check if all team member fields are empty
			const isTeamMembersEmpty = formData.team_members.every(
				(member) =>
					!member.team_member_full_name &&
					!member.team_member_mobile_number &&
					!member.team_member_email_address &&
					!member.team_member_designation &&
					!member.team_member_role,
			);

			// If all team members fields are empty, set team_members to an empty array
			if (isTeamMembersEmpty) {
				formData.team_members = [];
			}
			if (id) {
				updateReseller({ ...formData, id });
			} else {
				addReseller(formData);
			}
			setTriggerUpdate(false);
		}
	}, [triggerUpdate]);

	// Functions
	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const getDocumentObject = async (document_path, folder_name) => {
		let url = await getSignedUrlForGetObject(
			'resellers',
			document_path,
			folder_name,
		);
		let file_name = getFileNameFromDocPath(document_path);
		return {
			name: file_name,
			url: url,
			status: 'done',
		};
	};

	const setAllUploadedDocuments = async (data) => {
		// setFormData({
		// 	...form.getFieldsValue(),
		// 	add_on_commission: data.add_on_commission,
		// });
		// form.setFieldsValue({
		// 	...form.getFieldsValue(),
		// 	'add_on_commission.add_on_commission_type':
		// 		data.add_on_commission.add_on_commission_type,
		// 	'add_on_commission.referred_by_organization':
		// 		data.add_on_commission.referred_by_organization,
		// 	'add_on_commission.add_on_commission_percentage':
		// 		data.add_on_commission.add_on_commission_percentage,
		// 	'add_on_commission.add_on_commission_end_date': dayjs(
		// 		data.add_on_commission.add_on_commission_end_date,
		// 	),
		// });
		const documentPromises = uploadedDocuments.map(async (document) => {
			try {
				const document_path = data[document.key];
				const document_object = await getDocumentObject(
					document_path,
					data.s3_folder_name,
				);
				document.name = document_object.name;
				document.url = document_object.url;
				document.status = document_object.status;
				return document;
			} catch (error) {
				console.log('Try and catch error => ', error.message);
				return error;
			}
		});
		await Promise.all(documentPromises);
		setDataLoaded(true);
	};

	const uploadFiles = async (mobile_number) => {
		// keep document paths in an object
		let documentFields = {};
		let allUploadsSuccessful = true; // Track the success status of all uploads
		const s3FolderName = formData.s3_folder_name
			? formData.s3_folder_name
			: uuidv4();

		// Create an array of promises
		if (documentsToUpload.length > 0) {
			await Promise.all(
				documentsToUpload.map(async (document) => {
					const {
						fieldName,
						file,
						onError,
						onSuccess,
						onProgress,
						fileName,
					} = document;
					// Skip the document if it's already uploaded
					if (uploadedDocumentsToS3[fieldName]) {
						return;
					}
					let folder_name = `resellers`;
					let file_name = fileName;
					let get_ext = file.name.split('.');
					let ext = get_ext[get_ext.length - 1];

					try {
						const res = await axios.get(
							`${config.api.base_url}/get-signed-url?action=putObject&folder_name=${folder_name}&file_name=${file_name}&ext=${ext}&id=${s3FolderName}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(res.data.data, file, {
							onUploadProgress: ({ total, loaded }) => {
								onProgress(
									{
										percent: Math.round(
											(loaded / total) * 100,
										).toFixed(0),
									},
									file,
								);
							},
						});

						if (result.status === 200) {
							let documentPath = `/${folder_name}/${s3FolderName}/${file_name}.${ext}`;
							documentFields = {
								...documentFields,
								[fieldName]: documentPath,
							};

							setFormData((prev) => ({
								...prev,
								[fieldName]: documentPath,
							}));

							onSuccess(result, file);

							// Update the uploaded documents state
							setUploadedDocumentsToS3((prevState) => ({
								...prevState,
								[fieldName]: documentPath,
							}));
						} else {
							allUploadsSuccessful = false;
						}
					} catch (error) {
						console.log('Try and catch error => ', error.message);
						notification.error({
							message: `Unable to upload file - ${fieldName}`,
						});
						allUploadsSuccessful = false;
					}
				}),
			);
		}
		// Only update form data and trigger update if all uploads were successful
		if (allUploadsSuccessful) {
			setFormData((prevFormData) => ({
				...prevFormData,
				...documentFields,
				s3_folder_name: s3FolderName,
			}));

			setTriggerUpdate(true);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
		if (name == 'reseller_type') setSelectedResellerType(value);
	};

	const handleSelectChange = (value, name) => {
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleCommissionTypeChange = (e) => {
		setCommissionType(e.target.value);

		if (e.target.value === 'vendor_commission') {
			// setVendorChecked(true);
			// setPlacementChecked(false);
			setFormData({
				...formData,
				commission_type: 'vendor_commission',
				vendor_commission_criteria: 'percentage',
				placement_fees_criteria: null,
				placement_fees_fixed_value: 0,
				placement_fees_percentage_value: 0,
			});
			form.setFieldsValue({
				...form.getFieldsValue(),
				commission_type: 'vendor_commission',
				vendor_commission_criteria: 'percentage',
				placement_fees_criteria: null,
				placement_fees_fixed_value: null,
				placement_fees_percentage_value: null,
			});
		} else {
			// setPlacementChecked(true);
			// setVendorChecked(false);
			setFormData({
				...formData,
				commission_type: 'placement_fees',
				placement_fees_criteria: 'percentage',
				vendor_commission_criteria: null,
				vendor_commission_fixed_value: 0,
				vendor_commission_percentage_value: 0,
			});
			form.setFieldsValue({
				...form.getFieldsValue(),
				commission_type: 'placement_fees',
				placement_fees_criteria: 'percentage',
				vendor_commission_criteria: null,
				vendor_commission_fixed_value: null,
				vendor_commission_percentage_value: null,
			});
		}
		// handleInputChange(e);
	};

	// const handleAddOnCommissionChange = (value, name) => {
	// 	const [field, fieldName] = name.split('.');
	// 	const updatedFormData = {
	// 		...formData,
	// 		add_on_commission: {
	// 			...formData.add_on_commission,
	// 			[fieldName]:
	// 				fieldName == 'add_on_commission_end_date'
	// 					? value.format('YYYY-MM-DD')
	// 					: value,
	// 		},
	// 	};
	// 	setFormData(updatedFormData);
	// };

	const handleDateChanged = (date, name) => {
		if (date) {
			date.format('YYYY-MM-DD');
		}
		setFormData({
			...formData,
			[name]: date,
		});
	};

	const handleTenureInputChange = (e) => {
		const value = Number(e.target.value);
		// const tenureInMonths = formData.prefix === 'Years' ? value * 12 : value;
		// const tenureInMonths = formData.prefix === 'Years' ? value : value * 12;
		setFormData({ ...formData, tenure_of_agreement: value });
	};

	const handlePrefixChange = (value) => {
		const tenureInMonths =
			value === 'Years'
				? formData.tenure_of_agreement / 12
				: formData.tenure_of_agreement * 12;
		setFormData({
			...formData,
			tenure_of_agreement: tenureInMonths,
			prefix: value,
		});
		form.setFieldsValue({
			...form.getFieldsValue(),
			tenure_of_agreement: tenureInMonths,
		});
	};

	//calculate the expiry date
	function calculateExpiryDate(
		date_of_agreement,
		tenure_of_agreement,
		prefix,
	) {
		if (!date_of_agreement || !tenure_of_agreement) {
			return null; // Handle cases where the required data is missing
		}

		const startDate = new Date(date_of_agreement);
		let expiryDate;
		const integerPart = Math.floor(tenure_of_agreement);
		const fractionalPart = tenure_of_agreement - integerPart;
		if (prefix === 'Years') {
			// Add years to the start date
			expiryDate = new Date(
				startDate.setFullYear(startDate.getFullYear() + integerPart),
			);

			// Add fractional part as months (1 year = 12 months)
			if (fractionalPart > 0) {
				expiryDate = new Date(
					expiryDate.setMonth(
						expiryDate.getMonth() + fractionalPart * 12,
					),
				);
			}
		} else if (prefix === 'Months') {
			// Add months to the start date
			expiryDate = new Date(
				startDate.setMonth(startDate.getMonth() + integerPart),
			);
			console.log('calculateExpiryDate in else if');

			// Add fractional part as days (approximation: 1 month = 30 days)
			if (fractionalPart > 0) {
				expiryDate = new Date(
					expiryDate.setDate(
						expiryDate.getDate() + fractionalPart * 30,
					),
				);
			}
		}
		// if (prefix === 'Years') {
		// 	// Add years to the start date
		// 	expiryDate = new Date(
		// 		startDate.setFullYear(
		// 			startDate.getFullYear() + tenure_of_agreement,
		// 		),
		// 	);
		// } else if (prefix === 'Months') {
		// 	// Add months to the start date
		// 	expiryDate = new Date(
		// 		startDate.setMonth(startDate.getMonth() + tenure_of_agreement),
		// 	);
		// }
		const formattedDate = moment(expiryDate).format('YYYY-MM-DD');
		setFormData({
			...formData,
			agreement_expiry_date: formattedDate,
		});
		form.setFieldsValue({
			...form.getFieldsValue(),
			agreement_expiry_date: moment(expiryDate),
		});
		// return expiryDate;
	}

	useEffect(() => {
		setFormData({
			...formData,
			agreement_expiry_date: '',
		});
		form.setFieldsValue({
			...form.getFieldsValue(),
			agreement_expiry_date: null,
		});
		// Calculate the expiry date whenever date_of_agreement, tenure_of_agreement, or prefix changes
		if (formData.date_of_agreement && formData.tenure_of_agreement) {
			calculateExpiryDate(
				formData.date_of_agreement,
				formData.tenure_of_agreement,
				formData.prefix,
			);
		}
	}, [
		formData.date_of_agreement,
		formData.tenure_of_agreement,
		formData.prefix,
	]);

	function validateDropdownSelects(_, value) {
		if (value && value.length > maxDropdownSelections) {
			return Promise.reject(
				`Please select up to ${maxDropdownSelections} industries`,
			);
		}

		return Promise.resolve();
	}

	function validateLocationSelects(_, value) {
		if (value && value.length > maxLocationSelections) {
			return Promise.reject(
				`Please select up to ${maxLocationSelections} industries`,
			);
		}

		return Promise.resolve();
	}

	const handleTeamMemberChange = (name, value) => {
		const [fieldIndex, fieldName] = name.split(',');

		setFormData((prevState) => {
			const updatedTeamMembers = [...prevState.team_members];
			updatedTeamMembers[fieldIndex] = {
				...updatedTeamMembers[fieldIndex],
				[fieldName]: value,
			};
			return { ...prevState, team_members: updatedTeamMembers };
		});
	};

	const removeTeamMemberFormData = (index) => {
		setFormData((prevState) => {
			const updatedTeamMembers = [...prevState.team_members];
			updatedTeamMembers.splice(index, 1);
			updatedTeamMembers[index] = {
				...updatedTeamMembers[index],
			};
			return {
				...prevState,
				team_members: updatedTeamMembers,
			};
		});
	};

	return (
		<div className="mainContent">
			{id && !modulePermissions.sub_modules.includes('view-reseller') ? (
				<>
					{/* On Edit but not have view access */}

					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<Button
									style={{ fontSize: '18px' }}
									className="btn gilroy-bold"
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
								>
									Recruitment Partner
								</Button>
							</Col>
						}
					/>

					<div className="pageWrapper pt-0">
						<Card className="card">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>
										Unauthorized to access Recruitment
										Partner
									</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</Card>
					</div>
				</>
			) : !id &&
			  !modulePermissions.sub_modules.includes('add-reseller') ? (
				<>
					{/* On Add but not have add access  */}
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<Button
									style={{ fontSize: '18px' }}
									className="btn gilroy-bold"
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
								>
									Add New Recruitment Partner
								</Button>
							</Col>
						}
					/>
					<div className="pageWrapper pt-0">
						<Card className="card">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>
										Unauthorized to add Recruitment Partner
									</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</Card>
					</div>
				</>
			) : (
				dataLoaded && (
					<>
						<HeaderFile
							leftChild={
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={10}
									xl={10}
									className="headerHide"
								>
									<Button
										style={{ fontSize: '18px' }}
										className="btn gilroy-bold"
										onClick={() => navigate(-1)}
										icon={<ArrowLeftOutlined />}
									>
										{id ? (
											<>
												{`${formData.first_name} ${formData.last_name}`}
											</>
										) : (
											<>Add New Recruitment Partner</>
										)}
									</Button>
								</Col>
							}
						/>
						<div className="pageWrapper pt-0">
							<Card className="card">
								<Form
									scrollToFirstError={true}
									form={form}
									layout="vertical"
									// onFinish={onFinish}
									onFinish={() => {
										uploadFiles();
									}}
									initialValues={formData}
								>
									<Row>
										<Col xs={24} sm={24} lg={24}>
											<Form.Item
												name="reseller_type"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please choose reseller type',
													},
												]}
											>
												<Radio.Group
													name="reseller_type"
													onChange={handleInputChange}
													value={selectedResellerType}
													className="customRadio my-5"
													disabled={id ? true : false}
												>
													<Radio value="organization">
														Organization
													</Radio>
													<Radio value="freelancer">
														Freelancer
													</Radio>
												</Radio.Group>
											</Form.Item>
										</Col>
									</Row>
									<>
										<Row gutter={[30, 0]}>
											{/* If ID - fields with side Card */}
											{id ? (
												<>
													<Col
														xs={24}
														sm={24}
														lg={24}
													>
														<Row gutter={[30, 0]}>
															<Col
																xs={{
																	span: 24,
																	order: 2,
																}}
																sm={{
																	span: 12,
																	order: 1,
																}}
																lg={{
																	span: 16,
																	order: 1,
																}}
															>
																<Row
																	gutter={[
																		30, 0,
																	]}
																>
																	<Col
																		xs={24}
																		sm={12}
																		lg={12}
																	>
																		<Form.Item
																			name="first_name"
																			label="Owner's First Name"
																			className="formLable"
																			rules={[
																				{
																					required: true,
																					message:
																						'Please enter first name',
																				},
																			]}
																		>
																			<Input
																				onKeyDown={
																					handleKeyDown
																				}
																				name="first_name"
																				placeholder="Enter first name"
																				className="searchInputBrder removeBg"
																				size="large"
																				onChange={
																					handleInputChange
																				}
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		xs={24}
																		sm={12}
																		lg={12}
																	>
																		<Form.Item
																			name="last_name"
																			label="Owner's Last Name"
																			className="formLable"
																			rules={[
																				{
																					required: true,
																					message:
																						'Please enter last name',
																				},
																			]}
																		>
																			<Input
																				onKeyDown={
																					handleKeyDown
																				}
																				name="last_name"
																				className="searchInputBrder removeBg"
																				size="large"
																				placeholder="Enter last name"
																				onChange={
																					handleInputChange
																				}
																			/>
																		</Form.Item>
																	</Col>
																	{selectedResellerType ===
																	'organization' ? (
																		<>
																			<Col
																				xs={
																					24
																				}
																				sm={
																					12
																				}
																				lg={
																					12
																				}
																			>
																				<Form.Item
																					name="organization_name"
																					label="Organization Name"
																					className="formLable"
																					rules={[
																						{
																							required: true,
																							message:
																								'Please enter organization name',
																						},
																					]}
																				>
																					<Input
																						onKeyDown={
																							handleKeyDown
																						}
																						name="organization_name"
																						placeholder="Enter organization name"
																						className="searchInputBrder removeBg"
																						size="large"
																						onChange={
																							handleInputChange
																						}
																					/>
																				</Form.Item>
																			</Col>
																			<Col
																				xs={
																					24
																				}
																				sm={
																					12
																				}
																				lg={
																					12
																				}
																			>
																				<Form.Item
																					name="designation"
																					label="Designation"
																					className="formLable"
																					rules={[
																						{
																							required: true,
																							message:
																								'Please enter designation',
																						},
																					]}
																				>
																					<Input
																						onKeyDown={
																							handleKeyDown
																						}
																						name="designation"
																						placeholder="Enter designation"
																						className="searchInputBrder removeBg"
																						size="large"
																						onChange={
																							handleInputChange
																						}
																					/>
																				</Form.Item>
																			</Col>
																			<Col
																				xs={
																					24
																				}
																				sm={
																					12
																				}
																				lg={
																					12
																				}
																			>
																				<Form.Item
																					name="organization_type"
																					label="Type of Organization"
																					className="formLable"
																					rules={[
																						{
																							required: true,
																							message:
																								'Please select organization type',
																						},
																					]}
																				>
																					<Select
																						placeholder="Select type of organization"
																						name="organization_type"
																						size="large"
																						className="removeBg"
																						mode="multiple"
																						allowClear
																						onChange={(
																							value,
																							options,
																						) =>
																							handleSelectChange(
																								value,
																								'organization_type',
																							)
																						}
																					>
																						{organizationTypeList.map(
																							(
																								organization_type,
																							) => {
																								return (
																									<Option
																										key={
																											organization_type.id
																										}
																										value={
																											organization_type.id
																										}
																									>
																										{
																											organization_type.name
																										}
																									</Option>
																								);
																							},
																						)}
																					</Select>
																				</Form.Item>
																			</Col>
																			<Col
																				xs={
																					24
																				}
																				sm={
																					12
																				}
																				lg={
																					12
																				}
																			>
																				<Form.Item
																					name="legal_entity"
																					label="Legal Entity"
																					className="formLable"
																					rules={[
																						{
																							required: true,
																							message:
																								'Please select legal entity',
																						},
																					]}
																				>
																					<Select
																						placeholder="Select legal entity"
																						name="legal_entity"
																						size="large"
																						className="removeBg"
																						onChange={(
																							value,
																							options,
																						) =>
																							handleSelectChange(
																								value,
																								'legal_entity',
																							)
																						}
																					>
																						{legalEntityList.map(
																							(
																								legal_entity,
																							) => {
																								return (
																									<Option
																										key={
																											legal_entity.id
																										}
																										value={
																											legal_entity.id
																										}
																									>
																										{
																											legal_entity.name
																										}
																									</Option>
																								);
																							},
																						)}
																					</Select>
																				</Form.Item>
																			</Col>
																		</>
																	) : (
																		<>
																			<Col
																				xs={
																					24
																				}
																				sm={
																					24
																				}
																				lg={
																					24
																				}
																			>
																				<Form.Item
																					name="alias_name"
																					label="Alias Name"
																					className="formLable"
																					rules={[
																						{
																							required: true,
																							message:
																								'Please enter alias name',
																						},
																					]}
																				>
																					<Input
																						onKeyDown={
																							handleKeyDown
																						}
																						name="alias_name"
																						placeholder="Enter alias name"
																						className="searchInputBrder removeBg"
																						size="large"
																						onChange={
																							handleInputChange
																						}
																					/>
																				</Form.Item>
																			</Col>
																			<Col
																				xs={
																					24
																				}
																				sm={
																					12
																				}
																				lg={
																					12
																				}
																			>
																				<Form.Item
																					name="mobile_number"
																					label="Mobile Number"
																					className="formLable"
																					rules={[
																						{
																							required: true,
																							message:
																								'Please enter mobile number',
																						},
																						{
																							validator:
																								validateMobileNumber,
																						},
																					]}
																				>
																					<Input
																						onKeyDown={
																							handleKeyDown
																						}
																						name="mobile_number"
																						placeholder="Enter mobile number"
																						className="searchInputBrder removeBg"
																						size="large"
																						onChange={
																							handleInputChange
																						}
																					/>
																				</Form.Item>
																			</Col>
																			<Col
																				xs={
																					24
																				}
																				sm={
																					12
																				}
																				lg={
																					12
																				}
																			>
																				<Form.Item
																					name="email_address"
																					label="Email Address"
																					className="formLable"
																					rules={[
																						{
																							required: true,
																							message:
																								'Please enter email address',
																						},
																						{
																							type: 'email',
																							message:
																								'Please enter a valid email address',
																						},
																					]}
																				>
																					<Input
																						onKeyDown={
																							handleKeyDown
																						}
																						name="email_address"
																						placeholder="Enter email address"
																						className="searchInputBrder removeBg"
																						size="large"
																						onChange={
																							handleInputChange
																						}
																					/>
																				</Form.Item>
																			</Col>
																		</>
																	)}
																</Row>
															</Col>
															{/* Candidates Summary Card */}
															<Col
																xs={{
																	span: 24,
																	order: 1,
																}}
																sm={{
																	span: 12,
																	order: 1,
																}}
																lg={{
																	span: 8,
																	order: 1,
																}}
															>
																<div className="addEmployerBg">
																	<div className="addResellerBgImg"></div>
																	<Row
																		gutter={[
																			15,
																			15,
																		]}
																	>
																		<Col
																			xs={
																				24
																			}
																			sm={
																				24
																			}
																			md={
																				24
																			}
																		>
																			<div className="addEmpHeading gilroy-bold">
																				₹{' '}
																				{formData.total_earnings
																					? formData.total_earnings
																					: 0}
																			</div>
																			<div className="addEmpPara gilroy-semiBold">
																				Total
																				Earnings
																			</div>
																			<hr
																				className="mt-5"
																				style={{
																					height: '1px',
																				}}
																			/>
																		</Col>

																		<Col
																			xs={
																				24
																			}
																			sm={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<div className="addEmpHeading gilroy-bold">
																				{formData.candidates_applied
																					? formData.candidates_applied
																					: 0}
																			</div>
																			<div className="addEmpPara gilroy-semiBold">
																				Applied
																			</div>
																		</Col>
																		<Col
																			xs={
																				24
																			}
																			sm={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<div className="addEmpHeading gilroy-bold">
																				{formData.candidates_hired
																					? formData.candidates_hired
																					: 0}
																			</div>
																			<div className="addEmpPara gilroy-semiBold">
																				Hired
																			</div>
																		</Col>

																		<Col
																			xs={
																				24
																			}
																			sm={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<div className="addEmpHeading gilroy-bold">
																				{formData.candidates_billed
																					? formData.candidates_billed
																					: 0}
																			</div>
																			<div className="addEmpPara gilroy-semiBold">
																				Billed
																			</div>
																		</Col>
																		<Col
																			xs={
																				24
																			}
																			sm={
																				24
																			}
																			md={
																				12
																			}
																		>
																			<div className="addEmpHeading gilroy-bold">
																				{formData.candidates_joined
																					? formData.candidates_joined
																					: 0}
																			</div>
																			<div className="addEmpPara gilroy-semiBold">
																				Joined
																			</div>
																		</Col>
																	</Row>
																</div>
															</Col>
														</Row>
													</Col>
												</>
											) : (
												<>
													<Col xs={24} sm={12} lg={8}>
														<Form.Item
															name="first_name"
															label="Owner's First Name"
															className="formLable"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter first name',
																},
															]}
														>
															<Input
																onKeyDown={
																	handleKeyDown
																}
																name="first_name"
																placeholder="Enter first name"
																className="searchInputBrder removeBg"
																size="large"
																onChange={
																	handleInputChange
																}
															/>
														</Form.Item>
													</Col>
													<Col xs={24} sm={12} lg={8}>
														<Form.Item
															name="last_name"
															label="Owner's Last Name"
															className="formLable"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter last name',
																},
															]}
														>
															<Input
																onKeyDown={
																	handleKeyDown
																}
																name="last_name"
																className="searchInputBrder removeBg"
																size="large"
																placeholder="Enter last name"
																onChange={
																	handleInputChange
																}
															/>
														</Form.Item>
													</Col>
													<Col xs={24} sm={12} lg={8}>
														{selectedResellerType ==
														'organization' ? (
															<Form.Item
																name="designation"
																label="Designation"
																className="formLable"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter designation',
																	},
																]}
															>
																<Input
																	onKeyDown={
																		handleKeyDown
																	}
																	name="designation"
																	placeholder="Enter designation"
																	className="searchInputBrder removeBg"
																	size="large"
																	onChange={
																		handleInputChange
																	}
																/>
															</Form.Item>
														) : (
															<Form.Item
																name="alias_name"
																label="Alias Name"
																className="formLable"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter alias name',
																	},
																]}
															>
																<Input
																	onKeyDown={
																		handleKeyDown
																	}
																	name="alias_name"
																	placeholder="Enter alias name"
																	className="searchInputBrder removeBg"
																	size="large"
																	onChange={
																		handleInputChange
																	}
																/>
															</Form.Item>
														)}
													</Col>
												</>
											)}
											{/* if organization - Organization details */}

											{selectedResellerType ==
												'organization' &&
												!id && (
													<>
														<Col
															xs={24}
															sm={12}
															lg={8}
														>
															<Form.Item
																name="organization_name"
																label="Organization Name"
																className="formLable"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter organization name',
																	},
																]}
															>
																<Input
																	onKeyDown={
																		handleKeyDown
																	}
																	name="organization_name"
																	placeholder="Enter organization name"
																	className="searchInputBrder removeBg"
																	size="large"
																	onChange={
																		handleInputChange
																	}
																/>
															</Form.Item>
														</Col>
														<Col
															xs={24}
															sm={12}
															lg={8}
														>
															<Form.Item
																name="organization_type"
																label="Type of Organization"
																className="formLable"
																rules={[
																	{
																		required: true,
																		message:
																			'Please select organization type',
																	},
																]}
															>
																<Select
																	name="organization_type"
																	placeholder="Select type of organization"
																	size="large"
																	className="removeBg"
																	mode="multiple"
																	allowClear
																	onChange={(
																		value,
																		options,
																	) =>
																		handleSelectChange(
																			value,
																			'organization_type',
																		)
																	}
																>
																	{organizationTypeList.map(
																		(
																			organization_type,
																		) => {
																			return (
																				<Option
																					key={
																						organization_type.id
																					}
																					value={
																						organization_type.id
																					}
																				>
																					{
																						organization_type.name
																					}
																				</Option>
																			);
																		},
																	)}
																</Select>
															</Form.Item>
														</Col>

														<Col
															xs={24}
															sm={12}
															lg={8}
														>
															<Form.Item
																name="legal_entity"
																label="Legal Entity"
																className="formLable"
																rules={[
																	{
																		required: true,
																		message:
																			'Please select legal entity',
																	},
																]}
															>
																<Select
																	placeholder="Select legal entity"
																	name="legal_entity"
																	size="large"
																	className="removeBg"
																	onChange={(
																		value,
																		options,
																	) =>
																		handleSelectChange(
																			value,
																			'legal_entity',
																		)
																	}
																>
																	{legalEntityList.map(
																		(
																			legal_entity,
																		) => {
																			return (
																				<Option
																					key={
																						legal_entity.id
																					}
																					value={
																						legal_entity.id
																					}
																				>
																					{
																						legal_entity.name
																					}
																				</Option>
																			);
																		},
																	)}
																</Select>
															</Form.Item>
														</Col>
													</>
												)}

											{/* if ID and organization - Organization details  or Add Form */}
											{((id &&
												selectedResellerType ===
													'organization') ||
												!id) && (
												<>
													<Col xs={24} sm={12} lg={8}>
														<Form.Item
															name="mobile_number"
															label="Mobile Number"
															className="formLable"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter mobile number',
																},
																{
																	validator:
																		validateMobileNumber,
																},
															]}
														>
															<Input
																onKeyDown={
																	handleKeyDown
																}
																name="mobile_number"
																placeholder="Enter mobile number"
																className="searchInputBrder removeBg"
																size="large"
																onChange={
																	handleInputChange
																}
															/>
														</Form.Item>
													</Col>
													<Col xs={24} sm={12} lg={8}>
														<Form.Item
															name="email_address"
															label="Email Address"
															className="formLable"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter email address',
																},
																{
																	type: 'email',
																	message:
																		'Please enter a valid email address',
																},
															]}
														>
															<Input
																onKeyDown={
																	handleKeyDown
																}
																name="email_address"
																placeholder="Enter email address"
																className="searchInputBrder removeBg"
																size="large"
																onChange={
																	handleInputChange
																}
															/>
														</Form.Item>
													</Col>
												</>
											)}

											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="preferred_collar_job"
													label="Preferred Collar Job"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please select preferred collar job',
														},
													]}
												>
													<Select
														name="preferred_collar_job"
														placeholder="Select preferred collar job"
														size="large"
														className="removeBg"
														onChange={(
															value,
															options,
														) =>
															handleSelectChange(
																value,
																'preferred_collar_job',
															)
														}
													>
														{preferedCollarList.map(
															(
																preferred_collar,
															) => {
																return (
																	<Option
																		key={
																			preferred_collar.id
																		}
																		value={
																			preferred_collar.id
																		}
																	>
																		{
																			preferred_collar.name
																		}
																	</Option>
																);
															},
														)}
													</Select>
												</Form.Item>
											</Col>

											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="communication_mobile_number"
													label="Communication Mobile Number"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter communication mobile number',
														},
														{
															validator:
																validateMobileNumber,
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="communication_mobile_number"
														placeholder="Enter communication mobile number"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="communication_email_address"
													label="Communication Email Address"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter communication email address',
														},
														{
															type: 'email',
															message:
																'Please enter a valid email address',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="communication_email_address"
														placeholder="Enter communication email address"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="address"
													label="Address"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter address',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="address"
														placeholder="Enter address"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="pincode"
													label="Pincode"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter pincode',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="pincode"
														placeholder="Enter pincode"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="area"
													label="Area"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter area',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="area"
														placeholder="Enter area"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="city"
													label="City"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter city',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="city"
														placeholder="Enter city"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="state"
													label="State"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter state',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="state"
														placeholder="Enter state"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>

											<Col xs={24} sm={12} lg={24}>
												<Form.Item
													name="industries"
													label="Industry"
													className="formLable"
													validateFirst={true}
													validateTrigger="onChange"
													rules={[
														{
															required: true,
															message:
																'Please select industries',
														},
														{
															validator:
																validateDropdownSelects,
														},
													]}
												>
													<Select
														placeholder="Select industry"
														name="industries"
														size="large"
														className="removeBg"
														mode="multiple"
														allowClear
														optionFilterProp="children"
														onChange={(
															value,
															options,
														) =>
															handleSelectChange(
																value,
																'industries',
															)
														}
													>
														{getIndustriesState.apiState ===
															'success' &&
															getIndustriesState.data.all_industries.map(
																(industry) => {
																	return (
																		<Option
																			key={
																				industry.id
																			}
																			value={
																				industry.id
																			}
																		>
																			{
																				industry.industry_name
																			}
																		</Option>
																	);
																},
															)}
													</Select>
												</Form.Item>
											</Col>

											<Col xs={24} sm={12} lg={24}>
												<Form.Item
													name="preferred_job_roles"
													label="Functions"
													className="formLable"
													validateFirst={true}
													validateTrigger="onChange"
													rules={[
														{
															required: true,
															message:
																'Please select functions',
														},
														{
															validator:
																validateDropdownSelects,
														},
													]}
												>
													<Select
														name="preferred_job_roles"
														size="large"
														placeholder="Select functions"
														className="removeBg"
														mode="multiple"
														allowClear
														optionFilterProp="children"
														onChange={(
															value,
															options,
														) =>
															handleSelectChange(
																value,
																'preferred_job_roles',
															)
														}
													>
														{getIndustryCategoriesState.apiState ===
															'success' &&
															getIndustryCategoriesState.data.all_categories.map(
																(category) => {
																	return (
																		<Option
																			key={
																				category.id
																			}
																			value={
																				category.id
																			}
																		>
																			{
																				category.category_name
																			}
																		</Option>
																	);
																},
															)}
													</Select>
												</Form.Item>
											</Col>

											<Col xs={24} sm={12} lg={24}>
												<Form.Item
													name="preferred_job_locations"
													label="Preferred Job Locations"
													className="formLable"
													validateFirst={true}
													validateTrigger="onChange"
													rules={[
														{
															required: true,
															message:
																'Please select preferred job locations',
														},
														{
															validator:
																validateLocationSelects,
														},
													]}
												>
													<Select
														name="preferred_job_locations"
														size="large"
														placeholder="Select preferred job locations"
														className="removeBg"
														mode="multiple"
														allowClear
														onChange={(
															value,
															options,
														) =>
															handleSelectChange(
																value,
																'preferred_job_locations',
															)
														}
													>
														{getCitiesState.apiState ===
															'success' &&
															getCitiesState.data.all_cities.map(
																(city) => {
																	return (
																		<Option
																			key={
																				city
																			}
																			value={
																				city
																			}
																		>
																			{
																				city
																			}
																		</Option>
																	);
																},
															)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Card className="formCard mb-5">
											<p className="para2 mb-5">
												Add Team Member
											</p>
											<Form.List
												name="team_members"
												initialValue={
													formData.team_members
												}
											>
												{(fields, { add, remove }) => (
													<>
														{fields.map(
															(
																{
																	key,
																	name,
																	...restField
																},
																index,
															) => (
																<Row
																	gutter={[
																		30, 0,
																	]}
																	key={index}
																>
																	<Col
																		xs={24}
																		align="right"
																	>
																		{index !==
																			0 && (
																			<TabBtnNoBg
																				type="button"
																				onClick={() => {
																					remove(
																						name,
																					);
																					removeTeamMemberFormData(
																						index,
																					);
																				}}
																				style={{
																					background:
																						'none',
																					border: 'none',
																					boxShadow:
																						'none',
																				}}
																			>
																				<img
																					src="/images/delete-color-icon.svg"
																					alt=""
																				/>
																			</TabBtnNoBg>
																		)}
																	</Col>
																	<Col
																		xs={24}
																		sm={12}
																		lg={8}
																	>
																		<Form.Item
																			{...restField}
																			name={[
																				name,
																				'team_member_full_name',
																			]}
																			label="Team Member Full Name"
																			className="formLable"
																			rules={[
																				// {
																				// 	required: true,
																				// 	message:
																				// 		'Please enter Team Member Full Name',
																				// },

																				({
																					getFieldValue,
																				}) => ({
																					validator(
																						_,
																						value,
																					) {
																						const fields =
																							getFieldValue(
																								'team_members',
																							) ||
																							[];
																						const member =
																							fields[
																								name
																							];

																						if (
																							member
																						) {
																							const {
																								team_member_mobile_number,
																								team_member_email_address,
																								team_member_designation,
																								team_member_role,
																							} =
																								member;

																							if (
																								(team_member_mobile_number ||
																									team_member_email_address ||
																									team_member_designation ||
																									team_member_role) &&
																								!value
																							) {
																								return Promise.reject(
																									new Error(
																										'Name is required if email, designation, role, or mobile is filled!',
																									),
																								);
																							}
																						}
																						return Promise.resolve();
																					},
																				}),
																			]}
																		>
																			<Input
																				onKeyDown={
																					handleKeyDown
																				}
																				id={
																					index
																				}
																				name={[
																					name,
																					'team_member_full_name',
																				]}
																				onChange={(
																					e,
																				) =>
																					handleTeamMemberChange(
																						e
																							.target
																							.name,
																						e
																							.target
																							.value,
																					)
																				}
																				placeholder="Enter team member full name"
																				className="searchInputBrder removeBg"
																				size="large"
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		xs={24}
																		sm={12}
																		lg={8}
																	>
																		<Form.Item
																			{...restField}
																			name={[
																				name,
																				'team_member_mobile_number',
																			]}
																			label="Team Member Phone Number"
																			className="formLable"
																			rules={[
																				// {
																				// 	required: false,
																				// 	message:
																				// 		'Please enter Team Member Phone Number',
																				// },
																				{
																					validator:
																						validateMobileNumber,
																				},
																				({
																					getFieldValue,
																				}) => ({
																					validator(
																						_,
																						value,
																					) {
																						const fields =
																							getFieldValue(
																								'team_members',
																							) ||
																							[];
																						const member =
																							fields[
																								name
																							];

																						if (
																							member
																						) {
																							const {
																								team_member_full_name,
																								team_member_email_address,
																								team_member_designation,
																								team_member_role,
																							} =
																								member;

																							if (
																								(team_member_full_name ||
																									team_member_email_address ||
																									team_member_designation ||
																									team_member_role) &&
																								!value
																							) {
																								return Promise.reject(
																									new Error(
																										'Number is required if email, designation, role, or name is filled!',
																									),
																								);
																							}
																						}
																						return Promise.resolve();
																					},
																				}),
																			]}
																		>
																			<Input
																				onKeyDown={
																					handleKeyDown
																				}
																				id={
																					index
																				}
																				name={[
																					name,
																					'team_member_mobile_number',
																				]}
																				onChange={(
																					e,
																				) =>
																					handleTeamMemberChange(
																						e
																							.target
																							.name,
																						e
																							.target
																							.value,
																					)
																				}
																				placeholder="Enter team member phone number"
																				className="searchInputBrder removeBg"
																				size="large"
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		xs={24}
																		sm={12}
																		lg={8}
																	>
																		<Form.Item
																			{...restField}
																			name={[
																				name,
																				'team_member_email_address',
																			]}
																			label="Team Member Email ID"
																			className="formLable"
																			rules={[
																				// {
																				// 	required: false,
																				// 	message:
																				// 		'Please enter Team Member Email ID',
																				// },
																				{
																					type: 'email',
																					message:
																						'Please enter a valid email address',
																				},
																				({
																					getFieldValue,
																				}) => ({
																					validator(
																						_,
																						value,
																					) {
																						const fields =
																							getFieldValue(
																								'team_members',
																							) ||
																							[];
																						const member =
																							fields[
																								name
																							];

																						if (
																							member
																						) {
																							const {
																								team_member_full_name,
																								team_member_mobile_number,
																								team_member_designation,
																								team_member_role,
																							} =
																								member;

																							if (
																								(team_member_full_name ||
																									team_member_mobile_number ||
																									team_member_designation ||
																									team_member_role) &&
																								!value
																							) {
																								return Promise.reject(
																									new Error(
																										'Email is required if Name, designation, role, or mobile is filled!',
																									),
																								);
																							}
																						}
																						return Promise.resolve();
																					},
																				}),
																			]}
																		>
																			<Input
																				onKeyDown={
																					handleKeyDown
																				}
																				id={
																					index
																				}
																				name={[
																					name,
																					'team_member_email_address',
																				]}
																				onChange={(
																					e,
																				) =>
																					handleTeamMemberChange(
																						e
																							.target
																							.name,
																						e
																							.target
																							.value,
																					)
																				}
																				placeholder="Enter team member email id"
																				className="searchInputBrder removeBg"
																				size="large"
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		xs={24}
																		sm={12}
																		lg={8}
																	>
																		<Form.Item
																			{...restField}
																			name={[
																				name,
																				'team_member_designation',
																			]}
																			label="Team Member Designation"
																			className="formLable"
																			rules={[
																				// {
																				// 	required: true,
																				// 	message:
																				// 		'Please enter Team Member Designation',
																				// },
																				({
																					getFieldValue,
																				}) => ({
																					validator(
																						_,
																						value,
																					) {
																						const fields =
																							getFieldValue(
																								'team_members',
																							) ||
																							[];
																						const member =
																							fields[
																								name
																							];

																						if (
																							member
																						) {
																							const {
																								team_member_full_name,
																								team_member_mobile_number,
																								team_member_email_address,
																								team_member_role,
																							} =
																								member;

																							if (
																								(team_member_full_name ||
																									team_member_mobile_number ||
																									team_member_email_address ||
																									team_member_role) &&
																								!value
																							) {
																								return Promise.reject(
																									new Error(
																										'Designation is required if Name, email, role, or mobile is filled!',
																									),
																								);
																							}
																						}
																						return Promise.resolve();
																					},
																				}),
																			]}
																		>
																			<Input
																				onKeyDown={
																					handleKeyDown
																				}
																				id={
																					index
																				}
																				name={[
																					name,
																					'team_member_designation',
																				]}
																				onChange={(
																					e,
																				) =>
																					handleTeamMemberChange(
																						e
																							.target
																							.name,
																						e
																							.target
																							.value,
																					)
																				}
																				placeholder="Enter team member designation"
																				className="searchInputBrder removeBg"
																				size="large"
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		xs={24}
																		sm={12}
																		lg={8}
																	>
																		<Form.Item
																			{...restField}
																			name={[
																				name,
																				'team_member_role',
																			]}
																			label="Team Member Role"
																			className="formLable"
																			rules={[
																				// {
																				// 	required: true,
																				// 	message:
																				// 		'Please select Team Member Role',
																				// },
																				({
																					getFieldValue,
																				}) => ({
																					validator(
																						_,
																						value,
																					) {
																						const fields =
																							getFieldValue(
																								'team_members',
																							) ||
																							[];
																						const member =
																							fields[
																								name
																							];

																						if (
																							member
																						) {
																							const {
																								team_member_full_name,
																								team_member_mobile_number,
																								team_member_email_address,
																								team_member_designation,
																							} =
																								member;

																							if (
																								(team_member_full_name ||
																									team_member_mobile_number ||
																									team_member_email_address ||
																									team_member_designation) &&
																								!value
																							) {
																								return Promise.reject(
																									new Error(
																										'Role is required if Name email, designation, or mobile is filled!',
																									),
																								);
																							}
																						}
																						return Promise.resolve();
																					},
																				}),
																			]}
																		>
																			<Select
																				id={
																					index
																				}
																				name={[
																					name,
																					'team_member_role',
																				]}
																				allowClear
																				onChange={(
																					value,
																					options,
																				) =>
																					handleTeamMemberChange(
																						`${name},team_member_role`,
																						value,
																					)
																				}
																				placeholder="Select team member role"
																				className="removeBg"
																				size="large"
																			>
																				<Option value="64227938245caaac205221a8">
																					Reseller
																					Admin
																				</Option>
																			</Select>
																		</Form.Item>
																	</Col>
																	<Col
																		xs={24}
																	>
																		<Divider />
																	</Col>
																</Row>
															),
														)}
														<Row gutter={[30, 0]}>
															<Col
																xs={24}
																className="mt-5"
															>
																<SubmitNoBgBtn
																	type="button"
																	onClick={() =>
																		add()
																	}
																	style={{
																		color: Theme
																			.colors
																			.bgButton,
																		borderColor:
																			Theme
																				.colors
																				.bgButton,
																		padding:
																			'10px 15px',
																		width: 'auto',
																	}}
																>
																	Add Team
																	Member{' '}
																	<PlusOutlined />
																</SubmitNoBgBtn>
															</Col>
														</Row>
													</>
												)}
											</Form.List>
										</Card>
									</>

									{/* commission input card */}
									<Row gutter={[30, 0]}>
										<Col xs={24} sm={12} lg={12}>
											<Radio.Group
												name="commission_type"
												className="customRadio my-5"
												value={commissionType}
											>
												<Radio
													value="vendor_commission"
													onClick={
														handleCommissionTypeChange
													}
												>
													Recruitment Partner Payouts
												</Radio>
											</Radio.Group>

											<Card className="formCard mb-5">
												<Form.Item
													name={`vendor_commission_criteria`}
												>
													<Radio.Group
														name={`vendor_commission_criteria`}
														className="customRadio mb-5"
														onChange={
															handleInputChange
														}
														disabled={
															formData.commission_type !==
															'vendor_commission'
														}
													>
														<Radio value="percentage">
															Percentage
														</Radio>
														<Radio value="fixed">
															Fixed
														</Radio>
													</Radio.Group>
												</Form.Item>
												<Form.Item
													name={
														formData[
															`vendor_commission_criteria`
														] === 'fixed'
															? `vendor_commission_fixed_value`
															: `vendor_commission_percentage_value`
													}
													rules={[
														{
															required:
																formData.commission_type ===
																'vendor_commission',
															message: `Please enter ${
																formData[
																	`vendor_commission_criteria`
																] === 'fixed'
																	? 'amount'
																	: 'percentage'
															}`,
														},
														{
															validator: (
																_,
																value,
															) => {
																if (
																	formData[
																		`vendor_commission_criteria`
																	] !==
																		'fixed' &&
																	value > 100
																) {
																	return Promise.reject(
																		new Error(
																			'Percentage cannot be greater than 100',
																		),
																	);
																}
																return Promise.resolve();
															},
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name={
															formData[
																`vendor_commission_criteria`
															] == 'fixed'
																? `vendor_commission_fixed_value`
																: `vendor_commission_percentage_value`
														}
														placeholder="Enter Amount"
														className="searchInputBrder removeBg"
														type="any"
														size="large"
														min={1}
														onChange={
															handleInputChange
														}
														disabled={
															formData.commission_type !==
															'vendor_commission'
														}
													/>
												</Form.Item>
											</Card>
										</Col>
										<Col xs={24} sm={12} lg={12}>
											<Radio.Group
												name="commission_type"
												className="customRadio my-5"
												value={commissionType}
											>
												<Radio
													value="placement_fees"
													onClick={
														handleCommissionTypeChange
													}
												>
													Placement Fee From
													Recruitment Partner
												</Radio>
											</Radio.Group>
											<Card className="formCard mb-5">
												<Form.Item
													name={`placement_fees_criteria`}
												>
													<Radio.Group
														name={`placement_fees_criteria`}
														className="customRadio mb-5"
														onChange={
															handleInputChange
														}
														disabled={
															formData.commission_type !==
															'placement_fees'
														}
														// defaultValue="percentage"
													>
														<Radio value="percentage">
															Percentage
														</Radio>
														<Radio value="fixed">
															Fixed
														</Radio>
													</Radio.Group>
												</Form.Item>
												<Form.Item
													name={
														formData[
															`placement_fees_criteria`
														] === 'fixed'
															? `placement_fees_fixed_value`
															: `placement_fees_percentage_value`
													}
													rules={[
														{
															required:
																formData.commission_type ===
																'placement_fees',
															message: `Please enter ${
																formData[
																	`placement_fees_criteria`
																] === 'fixed'
																	? 'amount'
																	: 'percentage'
															}`,
														},
														{
															validator: (
																_,
																value,
															) => {
																if (
																	formData[
																		`placement_fees_criteria`
																	] !==
																		'fixed' &&
																	value > 100
																) {
																	return Promise.reject(
																		new Error(
																			'Percentage cannot be greater than 100',
																		),
																	);
																}
																return Promise.resolve();
															},
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name={
															formData[
																`placement_fees_criteria`
															] == 'fixed'
																? `placement_fees_fixed_value`
																: `placement_fees_percentage_value`
														}
														placeholder="Enter Amount"
														className="searchInputBrder removeBg"
														type="any"
														size="large"
														min={1}
														onChange={
															handleInputChange
														}
														disabled={
															formData.commission_type !==
															'placement_fees'
														}
													/>
												</Form.Item>
											</Card>
										</Col>
									</Row>
									{/* <Card className="formCard mb-5">
										<Row align="middle">
											<Col xs={12}>
												<p className="para2 mb-5">
													Referral Commission
												</p>
											</Col>
											<Col xs={12} align="right">
												<TabBtnNoBg
													style={{
														background: 'none',
														border: 'none',
														boxShadow: 'none',
													}}
												>
													<ReloadOutlined
														style={{
															color: Theme.colors
																.bgButton,
														}}
													/>
												</TabBtnNoBg>
											</Col>
										</Row>

										<Row gutter={[30, 0]}>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="add_on_commission.add_on_commission_type"
													label="Source"
													rules={[
														{
															required: true,
															message:
																'Please select add on commission type',
														},
													]}
													className="formLable"
												>
													<Select
														name="add_on_commission.add_on_commission_type"
														placeholder="Select commission type"
														size="large"
														className="removeBg"
														onChange={(
															value,
															options,
														) =>
															handleAddOnCommissionChange(
																value,
																'add_on_commission.add_on_commission_type',
															)
														}
													>
														<Option value="partner">
															Partner
														</Option>
														<Option value="employer">
															Employer
														</Option>
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="add_on_commission.referred_by_organization"
													label="Referred Entity"
													className="formLable mb-0"
												>
													<Select
														name="add_on_commission.referred_by_organization"
														onChange={(
															value,
															options,
														) => {
															handleAddOnCommissionChange(
																value,
																'add_on_commission.referred_by_organization',
															);
														}}
														className="removeBg w-100"
														size="large"
													>
														{formData
															.add_on_commission
															.add_on_commission_type ===
															'employer' &&
															getEmployerOrganizationsListState.apiState ===
																'success' &&
															getEmployerOrganizationsListState.data.all_organizations.map(
																(
																	organization,
																) => {
																	return (
																		<Option
																			key={
																				organization._id
																			}
																			value={
																				organization._id
																			}
																		>
																			{
																				organization.organization_name
																			}
																		</Option>
																	);
																},
															)}
														{formData
															.add_on_commission
															.add_on_commission_type ===
															'partner' &&
															getResellerOrganizationsListState.apiState ===
																'success' &&
															getResellerOrganizationsListState.data.all_organizations.map(
																(
																	organization,
																) => {
																	return (
																		<Option
																			key={
																				organization._id
																			}
																			value={
																				organization._id
																			}
																		>
																			{organization.reseller_type ===
																			'organization'
																				? organization.organization_name
																				: organization.alias_name}
																		</Option>
																	);
																},
															)}
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="add_on_commission.add_on_commission_percentage"
													label="Add on Commission Percentage"
													rules={[
														{
															required: true,
															message:
																'Please enter add on commission percentage',
														},
														{
															validator: (
																rule,
																value,
															) => {
																if (
																	value >=
																		1 &&
																	value <= 100
																) {
																	return Promise.resolve();
																} else {
																	return Promise.reject(
																		'Percentage value must be between 1 and 100.',
																	);
																}
															},
														},
													]}
													className="formLable mb-0"
												>
													<Input 													onKeyDown={handleKeyDown}
Number
														name="add_on_commission.add_on_commission_percentage"
														size="large"
														className="w-100"
														min={1}
														// max={100}
														onChange={(value) => {
															handleAddOnCommissionChange(
																value,
																'add_on_commission.add_on_commission_percentage',
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<Form.Item
													name="add_on_commission.add_on_commission_end_date"
													label="Add on Commission End Date"
													className="formLable mb-0"
												>
													<DatePicker
														size="large"
														className="searchInputBrder removeBg w-100"
														name="add_on_commission.add_on_commission_end_date"
														format="DD/MM/YYYY"
														placeholder="DD/MM/YYYY"
														onChange={(date) => {
															handleAddOnCommissionChange(
																date,
																'add_on_commission.add_on_commission_end_date',
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Card> */}
									<Col xs={24} sm={24} lg={24}>
										<Form.Item
											name="gajab_portal_name"
											label="Choose your Gazab Job Portal Name"
											className="formLable"
											rules={[
												{
													required: true,
													message:
														'Please enter gazab job portal name',
												},
											]}
										>
											<Input
												onKeyDown={handleKeyDown}
												name="gajab_portal_name"
												placeholder="Enter portal name"
												className="removeBg"
												size="large"
												addonAfter={jobSeekerPortal}
												onChange={handleInputChange}
											/>
										</Form.Item>
									</Col>
									{/* <Row gutter={[30, 0]}> */}
									<Card className="formCard mb-5">
										<Row>
											<Col xs={12}>
												<p className="para2 mb-5">
													Bank Details
												</p>
											</Col>
										</Row>
										<Row gutter={[30, 0]}>
											<Col xs={24} sm={8} lg={8}>
												<Form.Item
													name="bank_account_name"
													label="Account Name"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter account name',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="bank_account_name"
														placeholder="Enter account name"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={8}>
												<Form.Item
													name="bank_account_number"
													label="Account Number"
													className="formLable"
													validateTrigger="onChange"
													rules={[
														{
															required: true,
															message:
																'Please enter account number',
														},
														{
															pattern: /^[0-9]+$/,
															message:
																'Please enter valid account number',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="bank_account_number"
														placeholder="Enter account number"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={8}>
												<Form.Item
													name="bank_account_type"
													label="Account Type"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please select account type',
														},
													]}
												>
													<Select
														onChange={(
															value,
															options,
														) =>
															handleSelectChange(
																value,
																'bank_account_type',
															)
														}
														defaultValue={true}
														size="large"
													>
														{bankAccountTypesList.map(
															(
																bank_account_type,
															) => {
																return (
																	<Option
																		key={
																			bank_account_type.id
																		}
																		value={
																			bank_account_type.id
																		}
																	>
																		{
																			bank_account_type.name
																		}
																	</Option>
																);
															},
														)}
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={8}>
												<Form.Item
													name="bank_ifsc"
													label="IFSC Number"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter bank ifsc',
														},
														{
															pattern:
																/^[A-Z]{4}0[A-Z0-9]{6}$/,
															message:
																'IFSC code is invalid',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="bank_ifsc"
														placeholder="Enter bank ifsc"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
														onInput={(e) =>
															(e.target.value =
																e.target.value
																	.toUpperCase()
																	.replace(
																		/\s/g,
																		'',
																	))
														}
													/>
												</Form.Item>
											</Col>

											<Col xs={24} sm={8} lg={8}>
												<Form.Item
													name="bank_name"
													label="Bank Name"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter bank name',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="bank_name"
														placeholder="Enter bank name"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={8} lg={8}>
												<Form.Item
													name="bank_address"
													label="Bank Address"
													className="formLable"
													rules={[
														{
															required: true,
															message:
																'Please enter bank address',
														},
													]}
												>
													<Input
														onKeyDown={
															handleKeyDown
														}
														name="bank_address"
														placeholder="Enter bank address"
														className="searchInputBrder removeBg"
														size="large"
														onChange={
															handleInputChange
														}
													/>
												</Form.Item>
											</Col>

											<Col xs={24} className="mt-5">
												<Form.Item
													label="Upload scanned copy of cancelled cheque"
													name="bank_reference_document"
													className="formLable"
													getValueFromEvent={normFile}
													rules={[
														{
															required: true,
															message:
																'Please upload scanned copy of cancelled cheque',
														},
													]}
												>
													<Dragger
														maxCount={1}
														onRemove={(file) => {
															const updatedDocuments =
																documentsToUpload.filter(
																	(item) =>
																		item.fieldName !==
																		'bank_reference_document',
																);
															setDocumentsToUpload(
																updatedDocuments,
															);
														}}
														customRequest={(
															options,
														) => {
															const existingIndex =
																documentsToUpload.findIndex(
																	(item) =>
																		item.fieldName ===
																		'bank_reference_document',
																);

															if (
																existingIndex !==
																-1
															) {
																// Update existing object
																const updatedDocuments =
																	[
																		...documentsToUpload,
																	];
																updatedDocuments[
																	existingIndex
																] = {
																	...options,
																	fileName:
																		'BANK_REFERENCE',
																	fieldName:
																		'bank_reference_document',
																};
																setDocumentsToUpload(
																	updatedDocuments,
																);
															} else {
																// Add new object
																setDocumentsToUpload(
																	[
																		...documentsToUpload,
																		{
																			...options,
																			fileName:
																				'BANK_REFERENCE',
																			fieldName:
																				'bank_reference_document',
																		},
																	],
																);
															}

															options.onSuccess();
														}}
														defaultFileList={
															id
																? [
																		uploadedDocuments.find(
																			(
																				doc,
																			) =>
																				doc.key ===
																				'bank_reference_document',
																		),
																  ]
																: []
														}
														style={{
															background:
																'rgba(230, 104, 37, 0.05)',
														}}
													>
														<p className="ant-upload-drag-icon">
															<img
																src="/images/upload-icon.svg"
																alt=""
															/>
														</p>
														<div className="uploadHeading">
															Drag & drop files or{' '}
															<span>Browse</span>
														</div>
														<p className="para3">
															Supported formates:
															JPEG, PDF
														</p>
													</Dragger>
												</Form.Item>
											</Col>
										</Row>
									</Card>
									<Row gutter={[30, 0]}>
										<Col xs={24} sm={8}>
											<Form.Item
												label="MSME Registration Number"
												name="msme_registration_number"
												className="formLable"
											>
												<Input
													onKeyDown={handleKeyDown}
													name="msme_registration_number"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter MSME Registration Number"
													onChange={handleInputChange}
													onInput={(e) =>
														(e.target.value =
															e.target.value
																.toUpperCase()
																.replace(
																	/\s/g,
																	'',
																))
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item
												label="Upload MSME Registration Certificate"
												name="msme_registration_document"
												className="formLable"
												getValueFromEvent={normFile}
											>
												<Upload
													maxCount={1}
													className="uploadButton"
													onRemove={(file) => {
														const updatedDocuments =
															documentsToUpload.filter(
																(item) =>
																	item.fieldName !==
																	'msme_registration_document',
															);
														setDocumentsToUpload(
															updatedDocuments,
														);
													}}
													customRequest={(
														options,
													) => {
														const existingIndex =
															documentsToUpload.findIndex(
																(item) =>
																	item.fieldName ===
																	'msme_registration_document',
															);

														if (
															existingIndex !== -1
														) {
															// Update existing object
															const updatedDocuments =
																[
																	...documentsToUpload,
																];
															updatedDocuments[
																existingIndex
															] = {
																...options,
																fileName:
																	'MSME',
																fieldName:
																	'msme_registration_document',
															};
															setDocumentsToUpload(
																updatedDocuments,
															);
														} else {
															// Add new object
															setDocumentsToUpload(
																[
																	...documentsToUpload,
																	{
																		...options,
																		fileName:
																			'MSME',
																		fieldName:
																			'msme_registration_document',
																	},
																],
															);
														}

														options.onSuccess();
													}}
													defaultFileList={
														id
															? [
																	uploadedDocuments.find(
																		(doc) =>
																			doc.key ===
																			'msme_registration_document',
																	),
															  ]
															: []
													}
												>
													<Button
														block
														style={{
															textAlign: 'left',
															width: '100%',
														}}
													>
														Upload
														<UploadOutlined
															style={{
																float: 'right',
																fontSize:
																	'20px',
															}}
														/>
													</Button>
												</Upload>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[30, 0]}>
										<Col xs={24} sm={8}>
											<Form.Item
												label="PAN Number"
												name="pan_number"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please enter pan number',
													},
													{
														pattern:
															/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
														message:
															'PAN number is invalid',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													name="pan_number"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter PAN Number"
													onChange={handleInputChange}
													onInput={(e) =>
														(e.target.value =
															e.target.value
																.toUpperCase()
																.replace(
																	/\s/g,
																	'',
																))
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item
												label="Upload PAN Copy"
												name="pan_document"
												className="formLable"
												getValueFromEvent={normFile}
												rules={[
													{
														required: true,
														message:
															'Please upload pan document',
													},
												]}
											>
												<Upload
													maxCount={1}
													className="uploadButton"
													onRemove={(file) => {
														const updatedDocuments =
															documentsToUpload.filter(
																(item) =>
																	item.fieldName !==
																	'pan_document',
															);
														setDocumentsToUpload(
															updatedDocuments,
														);
													}}
													customRequest={(
														options,
													) => {
														const existingIndex =
															documentsToUpload.findIndex(
																(item) =>
																	item.fieldName ===
																	'pan_document',
															);

														if (
															existingIndex !== -1
														) {
															// Update existing object
															const updatedDocuments =
																[
																	...documentsToUpload,
																];
															updatedDocuments[
																existingIndex
															] = {
																...options,
																fileName: 'PAN',
																fieldName:
																	'pan_document',
															};
															setDocumentsToUpload(
																updatedDocuments,
															);
														} else {
															// Add new object
															setDocumentsToUpload(
																[
																	...documentsToUpload,
																	{
																		...options,
																		fileName:
																			'PAN',
																		fieldName:
																			'pan_document',
																	},
																],
															);
														}

														options.onSuccess();
													}}
													defaultFileList={
														id
															? [
																	uploadedDocuments.find(
																		(doc) =>
																			doc.key ===
																			'pan_document',
																	),
															  ]
															: []
													}
												>
													<Button
														block
														style={{
															textAlign: 'left',
														}}
													>
														Upload
														<UploadOutlined
															style={{
																float: 'right',
																fontSize:
																	'20px',
															}}
														/>
													</Button>
												</Upload>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[30, 0]}>
										<Col xs={24} sm={8}>
											<Form.Item
												label="CIN"
												name="cin_number"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please enter cin number',
													},
													{
														pattern:
															/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
														message:
															'CIN number is invalid',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													name="cin_number"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter CIN"
													onChange={handleInputChange}
													onInput={(e) =>
														(e.target.value =
															e.target.value
																.toUpperCase()
																.replace(
																	/\s/g,
																	'',
																))
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item
												label="Upload CIN Copy"
												name="cin_document"
												className="formLable"
												getValueFromEvent={normFile}
												rules={[
													{
														required: true,
														message:
															'Please upload cin document',
													},
												]}
											>
												<Upload
													maxCount={1}
													className="uploadButton"
													onRemove={(file) => {
														const updatedDocuments =
															documentsToUpload.filter(
																(item) =>
																	item.fieldName !==
																	'cin_document',
															);
														setDocumentsToUpload(
															updatedDocuments,
														);
													}}
													customRequest={(
														options,
													) => {
														const existingIndex =
															documentsToUpload.findIndex(
																(item) =>
																	item.fieldName ===
																	'cin_document',
															);

														if (
															existingIndex !== -1
														) {
															// Update existing object
															const updatedDocuments =
																[
																	...documentsToUpload,
																];
															updatedDocuments[
																existingIndex
															] = {
																...options,
																fileName: 'CIN',
																fieldName:
																	'cin_document',
															};
															setDocumentsToUpload(
																updatedDocuments,
															);
														} else {
															// Add new object
															setDocumentsToUpload(
																[
																	...documentsToUpload,
																	{
																		...options,
																		fileName:
																			'CIN',
																		fieldName:
																			'cin_document',
																	},
																],
															);
														}

														options.onSuccess();
													}}
													defaultFileList={
														id
															? [
																	uploadedDocuments.find(
																		(doc) =>
																			doc.key ===
																			'cin_document',
																	),
															  ]
															: []
													}
												>
													<Button
														block
														style={{
															textAlign: 'left',
														}}
													>
														Upload
														<UploadOutlined
															style={{
																float: 'right',
																fontSize:
																	'20px',
															}}
														/>
													</Button>
												</Upload>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[30, 0]}>
										<Col xs={24} sm={8}>
											<Form.Item
												label="GSTIN"
												name="gstin_number"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please enter gstin number',
													},
													{
														pattern:
															/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
														message:
															'GSTIN number is invalid',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													name="gstin_number"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter GSTIN"
													onChange={handleInputChange}
													onInput={(e) =>
														(e.target.value =
															e.target.value
																.toUpperCase()
																.replace(
																	/\s/g,
																	'',
																))
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item
												label="Upload GSTIN Copy"
												name="gstin_document"
												getValueFromEvent={normFile}
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please upload gstin document',
													},
												]}
											>
												<Upload
													maxCount={1}
													className="uploadButton"
													onRemove={(file) => {
														const updatedDocuments =
															documentsToUpload.filter(
																(item) =>
																	item.fieldName !==
																	'gstin_document',
															);
														setDocumentsToUpload(
															updatedDocuments,
														);
													}}
													customRequest={(
														options,
													) => {
														const existingIndex =
															documentsToUpload.findIndex(
																(item) =>
																	item.fieldName ===
																	'gstin_document',
															);

														if (
															existingIndex !== -1
														) {
															// Update existing object
															const updatedDocuments =
																[
																	...documentsToUpload,
																];
															updatedDocuments[
																existingIndex
															] = {
																...options,
																fileName:
																	'GSTIN',
																fieldName:
																	'gstin_document',
															};
															setDocumentsToUpload(
																updatedDocuments,
															);
														} else {
															// Add new object
															setDocumentsToUpload(
																[
																	...documentsToUpload,
																	{
																		...options,
																		fileName:
																			'GSTIN',
																		fieldName:
																			'gstin_document',
																	},
																],
															);
														}

														options.onSuccess();
													}}
													defaultFileList={
														id
															? [
																	uploadedDocuments.find(
																		(doc) =>
																			doc.key ===
																			'gstin_document',
																	),
															  ]
															: []
													}
												>
													<Button
														block
														style={{
															textAlign: 'left',
														}}
													>
														Upload
														<UploadOutlined
															style={{
																float: 'right',
																fontSize:
																	'20px',
															}}
														/>
													</Button>
												</Upload>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[30, 0]}>
										<Col xs={24} sm={12} lg={8}>
											<Form.Item
												name="date_of_agreement"
												label="Date of Agreement"
												className="formLable mb-0"
												rules={[
													{
														required: true,
														message:
															'Please select date of agreement',
													},
												]}
											>
												<DatePicker
													className="searchInputBrder removeBg w-100"
													size="large"
													format="DD/MM/YYYY"
													placeholder="DD/MM/YYYY"
													onChange={(date) => {
														handleDateChanged(
															date,
															'date_of_agreement',
														);
													}}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12} lg={8}>
											<Form.Item
												name="signed_by_both"
												label="Signed by both Parties?"
												className="formLable mb-0"
												rules={[
													{
														required: true,
														message:
															'Please select signed by both parties',
													},
												]}
											>
												<Select
													onChange={(
														value,
														options,
													) =>
														handleSelectChange(
															value,
															'signed_by_both',
														)
													}
													defaultValue={true}
													size="large"
												>
													<Option value={true}>
														Yes
													</Option>
													<Option value={false}>
														No
													</Option>
												</Select>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[30, 0]}>
										<Col xs={24} sm={12} lg={8}>
											<Form.Item
												name="tenure_of_agreement"
												label="Tenure of Agreement"
												className="formLable mb-0"
												rules={[
													{
														required: true,
														message:
															'Please enter tenure of agreement',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													type="number"
													// min={1}
													name="tenure_of_agreement"
													className="removeBg w-100"
													size="large"
													placeholder="Enter Tenure of Agreement"
													onChange={
														handleTenureInputChange
													}
													addonBefore={
														<div
															style={{
																width: 100,
																textAlign:
																	'center', // Optional: to center the text
															}}
														>
															Months
														</div>
														// <Select
														// 	style={{
														// 		width: 100,
														// 	}}
														// 	onChange={
														// 		handlePrefixChange
														// 	}
														// 	defaultValue="Months"
														// >
														// 	<Option value="Months">
														// 		Months
														// 	</Option>
														// 	<Option value="Years">
														// 		Years
														// 	</Option>
														// </Select>
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12} lg={8}>
											<Form.Item
												name="agreement_expiry_date"
												label="Agreement Expiry"
												className="formLable mb-0"
												rules={[
													{
														required: true,
														message:
															'Please select agreement expiry date',
													},
												]}
											>
												<DatePicker
													className="searchInputBrder removeBg w-100"
													size="large"
													format="DD/MM/YYYY"
													placeholder="DD/MM/YYYY"
													onChange={(date) => {
														handleDateChanged(
															date,
															'agreement_expiry_date',
														);
													}}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} className="mt-5">
											<Form.Item
												label="Agreement"
												name="agreement_document"
												className="formLable"
												getValueFromEvent={normFile}
												rules={[
													{
														required: true,
														message:
															'Please upload agreement',
													},
												]}
											>
												<Dragger
													maxCount={1}
													onRemove={(file) => {
														const updatedDocuments =
															documentsToUpload.filter(
																(item) =>
																	item.fieldName !==
																	'agreement_document',
															);
														setDocumentsToUpload(
															updatedDocuments,
														);
													}}
													accept=".pdf"
													customRequest={(
														options,
													) => {
														const existingIndex =
															documentsToUpload.findIndex(
																(item) =>
																	item.fieldName ===
																	'agreement_document',
															);

														if (
															existingIndex !== -1
														) {
															// Update existing object
															const updatedDocuments =
																[
																	...documentsToUpload,
																];
															updatedDocuments[
																existingIndex
															] = {
																...options,
																fileName:
																	'Agreement',
																fieldName:
																	'agreement_document',
															};
															setDocumentsToUpload(
																updatedDocuments,
															);
														} else {
															// Add new object
															setDocumentsToUpload(
																[
																	...documentsToUpload,
																	{
																		...options,
																		fileName:
																			'Agreement',
																		fieldName:
																			'agreement_document',
																	},
																],
															);
														}

														options.onSuccess();
													}}
													defaultFileList={
														id
															? [
																	uploadedDocuments.find(
																		(doc) =>
																			doc.key ===
																			'agreement_document',
																	),
															  ]
															: []
													}
													style={{
														background:
															'rgba(230, 104, 37, 0.05)',
													}}
												>
													<p className="ant-upload-drag-icon">
														<img
															src="/images/upload-icon.svg"
															alt=""
														/>
													</p>
													<div className="uploadHeading">
														Drag & drop files or{' '}
														<span>Browse</span>
													</div>
													<p className="para3">
														Supported formates:
														JPEG, PDF
													</p>
												</Dragger>
											</Form.Item>
										</Col>
									</Row>

									<Row>
										<Col xs={24} sm={24} align="left">
											<SubmitNoBgBtn
												className="mr-3"
												type="primary"
												htmlType="submit"
											>
												Cancel
											</SubmitNoBgBtn>
											<SubmitBtn
												style={{
													width: 'auto',
													padding: '10px 10px',
												}}
												type="primary"
												htmlType="submit"
											>
												{id
													? 'Save Reseller'
													: 'Add Recruitment Partner'}
											</SubmitBtn>
										</Col>
									</Row>
								</Form>
							</Card>
						</div>
					</>
				)
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	getIndustriesState: state.getIndustries,
	getIndustryCategoriesState: state.getIndustryCategories,
	getCitiesState: state.getCities,
	getAreaByPincodeState: state.getAreaByPincode,
	getAreaByPincodeResetState: state.getAreaByPincodeReset,
	getResellerByIdState: state.getResellerById,
	getResellerByIdResetState: state.getResellerByIdReset,
	addResellerState: state.addReseller,
	addResellerResetState: state.addResellerReset,
	updateResellerState: state.updateReseller,
	updateResellerResetState: state.updateResellerReset,
	getEmployerOrganizationsListState: state.getEmployerOrganizationsList,
	getResellerOrganizationsListState: state.getResellerOrganizationsList,
});

const mapDispatchToProps = (dispatch) => ({
	getIndustries: (params) => dispatch(getIndustriesApi(params)),
	getIndustryCategories: (params) =>
		dispatch(getIndustryCategoriesApi(params)),
	getCities: (params) => dispatch(getCitiesApi(params)),
	getAreaByPincode: (params) => dispatch(getAreaByPincodeApi(params)),
	getAreaByPincodeReset: () => dispatch(getAreaByPincodeReset()),
	getResellerById: (params) => dispatch(getResellerByIdApi(params)),
	getResellerByIdReset: (params) => dispatch(getResellerByIdReset(params)),
	addReseller: (params) => dispatch(addResellerApi(params)),
	addResellerReset: (params) => dispatch(addResellerReset(params)),
	updateReseller: (params) => dispatch(updateResellerApi(params)),
	updateResellerReset: (params) => dispatch(updateResellerReset(params)),
	getEmployerOrganizationsList: (params) =>
		dispatch(getEmployerOrganizationsListApi(params)),
	getResellerOrganizationsList: (params) =>
		dispatch(getResellerOrganizationsListApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditResellers);
