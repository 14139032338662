import styled from 'styled-components';

export const Button = styled.button`
	display: block;
	width: 100%;
	text-decoration: none;
	background-color: ${({ theme }) => theme.colors.bgButton};
	border-radius: 10px;
	color: ${({ theme }) => theme.colors.white};
	border: none;
	text-align: center;
	cursor: pointer;
	padding: 15px 0px;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
`;

export const ButtonBg = styled.button`
	background-color: ${({ theme }) => theme.colors.bgButton};
	color: ${({ theme }) => theme.colors.white};
	border: none;
	padding: 12px 30px;
	cursor: pointer;
	margin-left: 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 400;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
	border-radius: 22.5px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
`;
export const ButtonNoBg = styled.button`
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.text};
	border: none;
	padding: 12px 30px;
	cursor: pointer;
	margin-left: 15px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
	border-radius: 22.5px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
`;

export const TabBtnBg = styled.button`
	background-color: ${({ theme }) => theme.colors.bgButton};
	color: ${({ theme }) => theme.colors.white};
	border: none;
	padding: 5px 18.5px;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
	:disabled {
	  background-color: grey !important;
    cursor: auto !important
`;

export const TabBtnNoBg = styled.button`
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.text};
	border: 1px solid ${({ theme }) => theme.colors.bgButton};
	padding: 5px 15px;
	cursor: pointer;
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
`;
export const ButtonIcon = styled.button`
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.bgButton};
	border: 1px solid ${({ theme }) => theme.colors.border2};
	padding: 7px 10px;
	cursor: pointer;
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
`;

export const TabActiveBtnBg = styled.button`
	background-color: #16a34a;
	color: ${({ theme }) => theme.colors.white};
	border: 1px solid transparent;
	padding: 5px 15px;
	min-width: 130px;
	cursor: pointer;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
`;
export const Wrapper = styled.section`
	display: block;
`;
export const SubmitBtn = styled.button`
	font-family: 'Gilroy-Regular' !important;
	background-color: ${({ theme }) => theme.colors.bgButton};
	color: ${({ theme }) => theme.colors.white};
	border: none;
	padding: 10px 0px;
	width: 150px;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
`;

// export const SubmitNoBgBtn = styled.button`
// 	font-family: 'Gilroy-Regular' !important;
// 	background-color: ${({ theme }) => theme.colors.white};
// 	color: ${({ theme }) => theme.colors.text};
// 	border: 1px solid ${({ theme }) => theme.colors.border2};
// 	padding: 10px 0px;
// 	width: 150px;
// 	cursor: pointer;
// 	margin-bottom: 10px;
// 	font-size: 14px;
// 	line-height: 14px;
// 	font-weight: 500;
// 	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
// 	border-radius: 8px;
// 	transition: all 0.3s ease;
// 	-webkit-transition: all 0.3s ease 0s;
// 	-moz-transition: all 0.3s ease 0s;
// 	-o-transition: all 0.3s ease 0s;
// 	&:hover,
// 	&:active {
// 		transform: scale(0.96);
// 		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
// 	}
// 	a {
// 		text-decoration: none;
// 		color: rgb(255 255 255);
// 		font-size: 1.8rem;
// 	}
// 	.spinner {
// 		border: 2px solid ${({ theme }) => theme.colors.border2};
// 		border-top: 2px solid ${({ theme }) => theme.colors.primary};
// 		border-radius: 50%;
// 		width: 20px;
// 		height: 20px;
// 		animation: spin 1s linear infinite;
// 	}

// 	@keyframes spin {
// 		0% {
// 			transform: rotate(0deg);
// 		}
// 		100% {
// 			transform: rotate(360deg);
// 		}
// 	}

// 	.loading {
// 		/* Ensures spinner and text alignment */
// 	}

// 	/* Add spinner style for loading */
// 	// .spinner {
// 	// 	border: 2px solid ${({ theme }) => theme.colors.border2};
// 	// 	border-top: 2px solid ${({ theme }) =>
// 		theme.colors.primary}; /* Customize spinner color */
// 	// 	border-radius: 50%;
// 	// 	width: 20px;
// 	// 	height: 20px;
// 	// 	animation: spin 1s linear infinite;
// 	// }

// 	// /* Spinner animation */
// 	// @keyframes spin {
// 	// 	0% {
// 	// 		transform: rotate(0deg);
// 	// 	}
// 	// 	100% {
// 	// 		transform: rotate(360deg);
// 	// 	}
// 	// }

// 	// /* Button text should be hidden when loading */
// 	// &.loading {
// 	// 	padding-left: 30px; /* Space for the spinner */
// 	// }
// `;

export const SubmitNoBgBtn = styled.button`
	font-family: 'Gilroy-Regular' !important;
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.text};
	border: 1px solid ${({ theme }) => theme.colors.border2};
	padding: 10px 0px;
	width: 150px;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	transition: all 0.3s ease;
	display: flex; /* Ensures spinner and text alignment */
	align-items: center; /* Vertically align spinner and text */
	justify-content: center; /* Center align both spinner and text */
	gap: 8px; /* Adds space between spinner and text */

	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}

	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}

	.spinner {
		border: 2px solid ${({ theme }) => theme.colors.border2};
		border-top: 2px solid ${({ theme }) => theme.colors.primary};
		border-radius: 50%;
		width: 20px;
		height: 20px;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	/* Style for disabled state */
	:disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}
`;
const SubmitButton = ({ loading, children, ...props }) => {
	return (
		<SubmitNoBgBtn {...props}>
			{loading && <div className="spinner"></div>}
			<span>{children}</span>
		</SubmitNoBgBtn>
	);
};

export default SubmitButton;
